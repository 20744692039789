import React from "react";
import {
  Chart as ChartJS,
  CategoryScale,
  LinearScale,
  PointElement,
  LineElement,
  Title,
  Tooltip,
  Legend,
} from "chart.js";
import { Line } from "react-chartjs-2";

// Register Chart.js components
ChartJS.register(CategoryScale, LinearScale, PointElement, LineElement, Title, Tooltip, Legend);

const CustomerSatisfactionLineChart = () => {
  // Example data for states
  const states = ["California", "Texas", "New York", "Florida", "Illinois"];

  // Satisfaction data for last month and this month
  const lastMonthData = [70, 60, 85, 75, 80]; // Satisfaction percentages for last month
  const thisMonthData = [75, 65, 90, 80, 85]; // Satisfaction percentages for this month

  // Example income totals for each month
  const lastMonthIncome = 50000; // Last month's total income
  const thisMonthIncome = 55000; // This month's total income

  const data = {
    labels: states, // X-axis labels (states)
    datasets: [
      {
        label: "Last Month",
        data: lastMonthData,
        borderColor: "rgba(255, 99, 132, 1)", // Line color for last month
        backgroundColor: "rgba(255, 99, 132, 0.2)", // Fill color under the line
        tension: 0.4, // Smooth curve
        pointRadius: 5,
        pointBackgroundColor: "rgba(255, 99, 132, 1)", // Point color
      },
      {
        label: "This Month",
        data: thisMonthData,
        borderColor: "rgba(75, 192, 192, 1)", // Line color for this month
        backgroundColor: "rgba(75, 192, 192, 0.2)", // Fill color under the line
        tension: 0.4, // Smooth curve
        pointRadius: 5,
        pointBackgroundColor: "rgba(75, 192, 192, 1)", // Point color
      },
    ],
  };

  const options = {
    responsive: true,
    maintainAspectRatio: false, // Allows resizing
    plugins: {
      legend: {
        position: "top", // Legend position
      },
     
    },
    scales: {
      y: {
        beginAtZero: true,
        title: {
          display: true,
          text: "Satisfaction (%)",
        },
      },
      x: {
        title: {
          display: true,
          text: "States",
        },
      },
    },
  };

  return (
    <div style={{ width: "40vw", height: "28vh", margin: "0 auto" }}>
      {/* Line Chart */}
      <Line data={data} options={options} />
      
      {/* Total Income Display */}
      <div style={{ textAlign: "center", marginTop: "20px" }}>
        <p>Last Month: <strong>${lastMonthIncome.toLocaleString()} </strong>This Month:<strong>${thisMonthIncome.toLocaleString()} </strong></p> 
      
      </div>
    </div>
  );
};

export default CustomerSatisfactionLineChart;
