import React from 'react'
import {Routes,Route,BrowserRouter} from "react-router-dom"
import AdminLogin from './pages/AdminLogin'
import Home from './pages/dashboard/Home'
import Marchants from './pages/dashboard/marchants/Marchants'
import Setting from './pages/dashboard/Setting'
import OnGoingTeams from './pages/dashboard/OnGoingTeams'
import AddMarcant from './pages/dashboard/marchants/AddMarchant'
import MarchantProfile from './pages/dashboard/marchants/MarchantProfile'
import CreateVoucher from './pages/dashboard/voucher/CreateVoucher'
import PageNotFound from './pages/dashboard/PageNotFound'
import Category from './pages/dashboard/category/Category'
import AuthGuard from './components/ProtectedRoute/AuthGuard'
import EditMarchant from './pages/dashboard/marchants/EditMarchant'
import  { useEffect } from "react";


const BASE_URL = window.APP_CONFIG.API_BASE_URL;
export { BASE_URL };

export default function App() {


  //lear hasloggedin data after a certain time

  // useEffect(() => {


  
  //   const timer = setTimeout(() => {
  //     localStorage.removeItem("hasLoggedIn");
    //     localStorage.removeItem("userItem");
      //     localStorage.removeItem("showSuccessAlert");
      
  //   }, 100000); // 10000 ms = 10 seconds

    
  //   return () => clearTimeout(timer);
  // }, []);


  return (
    <>
    
    <BrowserRouter>

      <Routes>
        <Route path='/dashboard/login' exact element={<AdminLogin/>}></Route>
        <Route path='/' exact element={<AuthGuard><Home/></AuthGuard>}></Route>
        <Route path='/marchants' exact element={<AuthGuard><Marchants/></AuthGuard>}></Route>
        <Route path='/marchant/add' exact element={<AuthGuard><AddMarcant/></AuthGuard>}></Route>
        <Route 
        path='/marchant/edit/:id' exact
         element={
          <AuthGuard>
          <EditMarchant />
          </AuthGuard>

          }>  
          </Route>
        <Route path='/marchant/profile/:id' exact element={<AuthGuard><MarchantProfile/></AuthGuard>}></Route>
        <Route path='/Voucher/add' exact element={<AuthGuard><CreateVoucher/></AuthGuard>}></Route>
        <Route path='/setting' exact element={<AuthGuard><Setting/></AuthGuard>}></Route>
        <Route path='/ongoingteams' exact element={<AuthGuard><OnGoingTeams/></AuthGuard>}></Route>
        <Route path='/category' exact element={<AuthGuard><Category/></AuthGuard>}></Route>
        <Route path="*" element={<PageNotFound />} />
      </Routes>
    </BrowserRouter>


    </>
  )
}
