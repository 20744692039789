import React from 'react'
import Sidebar from '../../../components/Sidebar'
import Box from '@mui/material/Box';
import Navbar from '../../../components/Navbar';
import OnbordNewMarchent from './OnbordNewMarchent';
import Typography from "@mui/material/Typography";
import UpdateMarchantForm from './UpdateMarchantForm';

export default function EditMarchant() {
  return (

    <>
         <div className='bgcolor'>
    <Navbar></Navbar>
    <Box height={70}/>
  <Box sx={{ display: 'flex' }}>
    <Sidebar></Sidebar>
 
    <Box component="main" sx={{ flexGrow: 1, p: 3 }}>
    <Typography
            gutterBottom
            variant="h5"
            component="div"
            sx={{ padding: "20px",fontWeight: 'bold' }}
          >
           Update Marchent
          </Typography>
    <UpdateMarchantForm></UpdateMarchantForm>
      </Box>
    </Box>
    </div>
    </>

  )
}