import React from 'react'
import Sidebar from '../../../components/Sidebar'
import Box from '@mui/material/Box';
import Navbar from '../../../components/Navbar';
import Marchantlist from './MarchantList';


export default function Marchants() {
  return (

    <>
     <div className='bgcolor'>
    <Navbar></Navbar>
    <Box height={70}/>
  <Box sx={{ display: 'flex' }}>
    <Sidebar></Sidebar>
 
    <Box component="main" sx={{ flexGrow: 1, p: 3 }}>
    <Marchantlist></Marchantlist>
       
      </Box>
    </Box>
    </div>
    </>

  )
}
