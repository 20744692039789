import React from 'react'
import Sidebar from '../../../components/Sidebar'
import Box from '@mui/material/Box';
import Navbar from '../../../components/Navbar';
import VoucherForm from '../../dashboard/voucher/VoucherForm'
import Typography from "@mui/material/Typography";
import '../../../assets/css/voucherForm.css'


export default function CreateVoucher() {
  return (

    <>
    <Navbar></Navbar>
    <Box height={40}/>
  <Box sx={{ display: 'flex' }}>
    <Sidebar></Sidebar>
 
    <Box component="main" sx={{ flexGrow: 1, p: 3 }}>
    <Typography
            gutterBottom
            variant="h5"
            component="div"
            sx={{ padding: "20px" ,fontWeight: 'bold'}}
          >
         Voucher Create for shanto shop
          </Typography>
          <VoucherForm></VoucherForm>
 
      </Box>
    </Box>
    </>

  )
}
