
// const BASE_URL = window.APP_CONFIG.API_BASE_URL;
// export { BASE_URL };
// Fetch categories from the API
export const fetchCategories = async () => {
    try {
      const response = await fetch("/v1/categories");
  
      if (!response.ok) {
        throw new Error(`Failed to fetch categories. Status: ${response.status}`);
      }
  
      const data = await response.json();
      return data; 
    } catch (error) {

      throw error; 
    }
  };
  
 // create category
 export const createCategory = async (categoryName) => {
    try {
    
      const formData = new FormData();
      formData.append("name", categoryName);
  
      const response = await fetch("/v1/create-category", {
        method: "POST",
        body: formData, 
      });
  
      if (!response.ok) {
        throw new Error("Failed to create category");
      }
  
      const data = await response.json();
      return data; 
    } catch (error) {

      throw error;
    }
  };

 // Update category
export const updateCategory = async (id, updatedName) => {
    const url = `/v1/update-category?id=${id}`;
    const formData = new FormData();
    formData.append("name", updatedName);
  
    const response = await fetch(url, {
      method: "POST",
      body: formData,
    });
  
    if (!response.ok) {
      throw new Error("Failed to update category");
    
    }
  
    return await response.json();
  };

  // Delete category
export const deleteCategory = async (categoryId) => {
  try {
    const response = await fetch(`/v1/delete-category?id=${categoryId}`, {
      method: 'DELETE',
      headers: {
        'Content-Type': 'application/json',
      },
    });
    if (!response.ok) {
      throw new Error(`Error deleting category: ${response.statusText}`);
    }
    return await response.json();
  } catch (error) {
    throw error;
  }
};

//fetch marchants data
export const fetchMerchants = async (token) => {

  try {
    const response = await fetch("/v1/merchant", {
      method: "GET",
      headers: {
        Authorization:token, 
        "Content-Type": "application/json",
      },
    });

    if (!response.ok) {

      throw new Error(`Error: ${response.status} ${response.statusText}`);
    }

    

    const data = await response.json();
    return data; 
  } catch (error) {
    throw error;
  
  }
};

//fetch marchants data by id
export const fetchMerchantsById = async (id,token) => {

  try {
    const response = await fetch(`/v1/merchant?id=${id}`, {
      method: "GET",
      headers: {
        Authorization:token, 
        "Content-Type": "application/json",
      },
    });

    if (!response.ok) {

      throw new Error(`Error: ${response.status} ${response.statusText}`);
    }

    

    const data = await response.json();
    return data; 
  } catch (error) {
    throw error;
  
  }
};

//create new marchants
export const createMerchant = async (merchantData,token) => {
  try {
    const response = await fetch("/v1/create-merchant", {
      method: "POST",
      headers: {
        Authorization:token, 
        "Content-Type": "application/json",
        
      },
      body: JSON.stringify(merchantData),
    });

    if (response.ok) {
      const result = await response.json();
      return { success: true, data: result };
    } else {
      const errorData = await response.json();
      return { success: false, error: errorData };
    }
  } catch (error) {
  
    return { success: false, error: "An unexpected error occurred." };
  }
};

//upload brand logo
export const uploadLogo = async (file, folderName = "merchants") => {
  const formData = new FormData();
  formData.append("file", file);
  formData.append("folder_name", folderName);

  try {
    const response = await fetch("/v1/image-upload?folder_name=merchants", {
      method: "POST",
      body: formData,
    });

    if (response.ok) {
      const data = await response.json();
    
      return { success: true, fileUrl: data.url }; 
    } else {
      const errorData = await response.json();
      return { success: false, message: errorData.message || "Failed to upload logo" };
    }
  } catch (error) {

    return { success: false, message: "Network error. Please try again later." };
  }
};

//edit marchant
export const updateMerchant = async (id,merchantData,token) => {

  try {
    const response = await fetch(`/v1/update-merchant?id=${id}`, {
      method: "POST",
      headers: {
        Authorization:token, 
        "Content-Type": "application/json",

      },
      body: JSON.stringify(merchantData),
    });

    const result = await response.json();

    if (!response.ok) {
      throw new Error(result.message || "Failed to update merchant.");
    }

    return result;
  } catch (error) {
  
    throw error;
  }
};

//delete marchant
  export const deleteMarchant = async (marchantId,token) => {
    try {
      const response = await fetch(`/v1/delete-merchant?id=${marchantId}`, {
        method: 'DELETE',
        headers: {
          Authorization:token, 
          'Content-Type': 'application/json',
        },
      });
      if (!response.ok) {
        throw new Error(`Error deleting marchant: ${response.statusText}`);
      }
      return await response.json();
    } catch (error) {
      throw error;
    }
  };

  // get country
  export const fetchCountry = async (token) => {

    try {
      const response = await fetch("/v1/country", {
        method: "GET",
        headers: {
          Authorization:token, 
          "Content-Type": "application/json",
        },
      });
  
      if (!response.ok) {
        throw new Error(`Error: ${response.status} ${response.statusText}`);
      }
  
      const data = await response.json();
      return data; 
    } catch (error) {
      throw error;
    }
  };

  //create voucher

  export const createVoucher= async (voucherData,token) => {
    try {

      const formData = new FormData();
// Append all voucher data to formData
formData.append("voucher_name", voucherData.voucher_name);
formData.append("price", voucherData.price);
formData.append("discount", voucherData.discount);
formData.append("merchant_id", voucherData.merchant_id);
formData.append("category_id", voucherData.category_id);
formData.append("country_id", voucherData.country_id);
formData.append("discount_percentage", voucherData.discount_percentage);
formData.append("caution", voucherData.caution);
formData.append("required_team_member", voucherData.required_team_member);
formData.append("minimum_team_duration", voucherData.minimum_team_duration);
formData.append("team_discount_percentage", voucherData.team_discount_percentage);




      const response = await fetch("/v1/create-voucher", {
        method: "POST",
        headers: {
          Authorization:token, 
        
          
        },
        body:formData,
      });
  
      const result = await response.json();

      if (!response.ok) {
          throw new Error(result.message || "Failed to create voucher");
      }

      return result;
  } catch (error) {
      throw new Error(error.message || "Something went wrong. Please try again.");
  }
  };

  //delete voucher
  export const deleteVoucher = async (voucherId,token) => {
    try {
      const response = await fetch(`/v1/delete-voucher?id=${voucherId}`, {
        method: 'DELETE',
        headers: {
          Authorization:token, 
          'Content-Type': 'application/json',
        },
      });
      if (!response.ok) {
        throw new Error(`Error deleting vouhcer: ${response.statusText}`);
      }
      return await response.json();
    } catch (error) {
      throw error;
    }
  };

//  edit voucher

export const updateVoucher = async (id,voucherData,token) => {

  try {
  
    const formData = new FormData();
    // Append all voucher data to formData
    formData.append("voucher_name", voucherData.voucher_name);
    formData.append("price", voucherData.price);
    formData.append("discount", voucherData.discount);
    formData.append("merchant_id", voucherData.merchant_id);
    formData.append("category_id", voucherData.category_id);
    formData.append("country_id", voucherData.country_id);
    formData.append("discount_percentage", voucherData.discount_percentage);
    formData.append("caution", voucherData.caution);
    formData.append("required_team_member", voucherData.required_team_member);
    formData.append("minimum_team_duration", voucherData.minimum_team_duration);
    formData.append("team_discount_percentage", voucherData.team_discount_percentage);

    const response = await fetch(`/v1/update-voucher?id=${id}`, {
      method: "POST",
      headers: {
        Authorization:token, 
      

      },
      body:formData,
    });

    const result = await response.json();

  

    if (!response.ok) {
      throw new Error(result.message || "Failed to update Voucher.");
    }

    return result;
  } catch (error) {
    console.error("Error:", error);
    throw error;
  }
};

//fetch admin dashboard data
export const fetchAdminDashboard = async (token) => {

  try {
    const response = await fetch("/v1/admin-dashboard", {
      method: "GET",
      headers: {
        Authorization:token, 
        "Content-Type": "application/json",
      },
    });

    if (!response.ok) {

      throw new Error(`Error: ${response.status} ${response.statusText}`);
    }

    

    const data = await response.json();
    return data; 
  } catch (error) {
    throw error;
  
  }
};

//fetch on going teams

export const fetchOngoingTeams = async (page = 1, limit = 2,token) => {
  try {
      const response = await fetch(`/v1/ongoing-teams?page=${page}&limit=${limit}`,{

    method: "GET",
      headers: {
        Authorization:token, 
        "Content-Type": "application/json",
      },
    });

      if (!response.ok) {
          throw new Error("Failed to fetch teams");
      }
      return await response.json(); 
  } catch (error) {
      console.error("API Error:", error);
      throw error;
  }
};