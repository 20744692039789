// src/components/VoucherCreateForm.js
import React, { useState,useEffect } from "react";
import '../../../assets/css/voucherForm.css'
import CloseIcon from '@mui/icons-material/Close';
import { IconButton } from '@mui/material';
import Swal from "sweetalert2";
import { fetchCategories,createVoucher } from "../../../api/Api";
import Alert from '@mui/material/Alert';
import AlertTitle from '@mui/material/AlertTitle';
import CircularProgress from '@mui/material/CircularProgress';



const VoucherCreateForm = ({CloseEvent,MarchantData,onSuccess}) => {
  const [formData, setFormData] = useState({
    voucherName: "",
    price: "",
    discount: "10", 
    marchantId: MarchantData.ID, 
    categoryId: "", 
    countryId: "1",
    discountPercentage: "0",  
    caution: "",
    requiredTeamMember: "0", 
    minimumTeamDuration: "0",
    teamDiscountPercentage: "0"



  });
    const [loading, setLoading] = useState(false);

  const handleChange = (e) => {
    const { name, value } = e.target;
    setFormData({ ...formData, [name]: value });
  };


    //get category
    const [category, setCategory] = useState([]);
    const [categoryError, setCategoryError] = useState("");
  
      const getCategory = async () => {
        try {
    
          const data = await fetchCategories();
          setCategory(data.data); 
      
        } catch (err) {
          setCategoryError(err.message);
        } 
      };
  
  
        useEffect(() => {
          getCategory();
    
        }, []);

    let isSubmitting = false;

    const handleSubmit = async (e) => {
    e.preventDefault();
     
  if (isSubmitting || loading) return; // Prevent multiple clicks
  isSubmitting = true;

    setLoading(true);

    // Prepare body data


    const requestBody = {
      voucher_name: formData.voucherName,
      price: parseFloat(formData.price), 
      discount: formData.discount,
      merchant_id: formData.marchantId,
      category_id: formData.categoryId,
      country_id: formData.countryId,
      discount_percentage: parseFloat(formData.discountPercentage) || 0,
      caution: formData.caution || "",
      required_team_member: parseInt(formData.requiredTeamMember) || 0,
      minimum_team_duration: parseInt(formData.minimumTeamDuration) || 0,
      team_discount_percentage: parseFloat(formData.teamDiscountPercentage) || 0
  };

  try {
   await createVoucher(requestBody);
   

    Swal.fire({
      icon: "success",
      title: "Voucher Created Successfully!",
      toast: true,
      position: "top-right",
      
      showConfirmButton: false,
      timer: 1000,
      timerProgressBar: true,
      background: "linear-gradient(to right, #008000, #32CD32)", // Green gradient success
      color: "#fff",
      iconColor: "#fff",
      customClass: {
        popup: "premium-toast-popup",
        container: "swal-toast-container",
      },
    })

    setFormData({
        voucherName: "",
        price: "",
        discount: "0",
        categoryId: "",
        minimumTeamDuration: "0",
        caution: "",
        requiredTeamMember: "0",
        teamDiscountPercentage: "0"
    });

    if (onSuccess) onSuccess(); 
    CloseEvent();
  
} catch (error) {
    Swal.fire({
      icon: "error",
      title: "Failed to Create voucher!",
      toast: true,
      position: "top-right",
      
      showConfirmButton: false,
      timer: 1000,
      timerProgressBar: true,
      background: "linear-gradient(to right, red, red)", 
      color: "#fff",
      iconColor: "#fff",
      customClass: {
        popup: "premium-toast-popup",
        container: "swal-toast-container",
      },
    })
} finally {
  isSubmitting = false;
    setLoading(false);
}  
    
  };

  return (

    
    <div className="voucher-form-container">

<h2 style={{ textAlign: "center", color: "#333", marginBottom: "20px" }}>
        Create Voucher For {MarchantData.shop_name}
      </h2>

      <IconButton style={{position:"absolute",top:'0',right:'0'}} 
      onClick={CloseEvent}>
      <CloseIcon />

      </IconButton>
      <form onSubmit={handleSubmit}>
        {/* Row 1: Voucher Name, Price, Team Discount */}
        <div className="form-row">
          <div className="form-group">
            <label  style={{
              display: "block",
              marginBottom: "5px",
              color: "#555",
              fontWeight: "bold",
            }}>Voucher Name</label>
            <input
              type="text"
              name="voucherName"
              value={formData.voucherName}
              onChange={handleChange}
              required
            />
          </div>
          <div className="form-group">
            <label  style={{
              display: "block",
              marginBottom: "5px",
              color: "#555",
              fontWeight: "bold",
            }}>Price</label>
            <input
              type="number"
              name="price"
              value={formData.price}
              onChange={handleChange}
              required
            />
          </div>
          <div className="form-group">
            <label  style={{
              display: "block",
              marginBottom: "5px",
              color: "#555",
              fontWeight: "bold",
            }}>Individual Discount(%)</label>
            <input
              type="number"
              name="discountPercentage"
              value={formData.discountPercentage}
              onChange={handleChange}
              required
            />
          </div>
        </div>

        {/* Row 2: Category, Minimum Team Duration */}
        <div className="form-row">
          <div className="form-group">
            <label  style={{
              display: "block",
              marginBottom: "5px",
              color: "#555",
              fontWeight: "bold",
            }}>Category</label>
          
                    
                              <select
                                  type="text"
                        name="categoryId"
                        value={formData.categoryId}
                        onChange={handleChange}
                        required
                      >
                     <option value="">Select Category</option>
                      {category.map((row) => {
          
                        return(
                      
                        <option key={row.ID} value={row.ID}> 
                                       {row.name}
                        </option>
                        )
                      })
          
                      }
                      
                      </select>
                    
                      {categoryError ?  <Alert severity="error" sx={{ mt: 2 }}>
                               <AlertTitle>Error</AlertTitle>
                                 There was an issue fetching the category data..
                               </Alert>
                                       : ""}
          </div>
          <div className="form-group">
            <label  style={{
              display: "block",
              marginBottom: "5px",
              color: "#555",
              fontWeight: "bold",
            }}>Minimum Team Duration (hours)</label>
            <input
              type="number"
              name="minimumTeamDuration"
              value={formData.minimumTeamDuration}
              onChange={handleChange}
              required
            />
          </div>
          <div className="form-group">
            <label  style={{
              display: "block",
              marginBottom: "5px",
              color: "#555",
              fontWeight: "bold",
            }}>Caution (optional)</label>
            <input
              type="text"
              name="caution"
              value={formData.caution}
              onChange={handleChange}
            />
          </div>
        </div>

        {/* Row 3: Required Team Members */}
        <div className="form-row">
          <div className="form-group">
            <label  style={{
              display: "block",
              marginBottom: "5px",
              color: "#555",
              fontWeight: "bold",
            }}>Required Team Members</label>
            <input
              type="number"
              name="requiredTeamMember"
              value={formData.requiredTeamMember}
              onChange={handleChange}
              required
            />
          </div>
          <div className="form-group">
            <label  style={{
              display: "block",
              marginBottom: "5px",
              color: "#555",
              fontWeight: "bold",
            }}>Team Discount(%)</label>
            <input
              type="number"
              name="teamDiscountPercentage"
              value={formData.teamDiscountPercentage}
              onChange={handleChange}
              required
            />
          </div>
        </div>

        {/* Submit Button */}
        <div className="form-row">
        <button
  type="submit"
  disabled={loading} // Prevents multiple clicks
  style={{
    width: "100%",
    padding: "10px",
    backgroundColor: loading ? "lightgray" : "gray",
    color: "#fff",
    border: "none",
    borderRadius: "4px",
    fontSize: "16px",
    fontWeight: "bold",
    cursor: loading ? "not-allowed" : "pointer",
  }}
>
  {loading ? (
    <CircularProgress size={16} sx={{ color: "inherit" }} />
  ) : (
    "Submit"
  )}
</button>
        </div>
      </form>
    </div>
  );
};

export default VoucherCreateForm;
