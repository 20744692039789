// src/components/SalesReportChart.js
import React from 'react';
import { Line } from 'react-chartjs-2';
import {
  Chart as ChartJS,
  CategoryScale,
  LinearScale,
  PointElement,
  LineElement,
  Title,
  Tooltip,
  Legend,
} from 'chart.js';

// Register required Chart.js components
ChartJS.register(CategoryScale, LinearScale, PointElement, LineElement, Title, Tooltip, Legend);

const Sales = () => {
  // Sample sales data
  const data = {
    labels: ['January', 'February', 'March', 'April', 'May', 'June'], // X-axis labels
    datasets: [
      {
        label: 'Monthly Sales ($)',
        data: [1200, 1900, 3000, 5000, 7000, 4000], // Sales data
        borderColor: 'rgba(75, 192, 192, 1)', // Line color
        backgroundColor: 'rgba(75, 192, 192, 0.2)', // Fill under the line
        borderWidth: 2,
        tension: 0.4, // Smooth curve
      },
    ],
  };

  // Chart options
  const options = {
    responsive: true,
    plugins: {
      legend: {
        display: true,
        position: 'top',
      },
      tooltip: {
        enabled: true,
        callbacks: {
          label: (context) => `$${context.raw}`, // Format tooltip value
        },
      },
    },
    scales: {
      x: {
        title: {
          display: true,
          text: 'Months',
          font: { weight: 'bold' },
        },
      },
      y: {
        title: {
          display: true,
          text: 'Sales ($)',
          font: { weight: 'bold' },
        },
        ticks: {
          callback: (value) => `$${value}`, // Add $ symbol to y-axis
        },
      },
    },
  };

  return (
    <div style={{ width: '70%', margin: '0 auto' }}>
    
      <Line data={data} options={options} />
    </div>
  );
};

export default Sales;
