import React,{useState,useEffect} from 'react'
import Sidebar from '../../../components/Sidebar'
import Box from '@mui/material/Box';
import Navbar from '../../../components/Navbar';
import Typography from "@mui/material/Typography";
import Grid from '@mui/material/Grid';
import MarchentInfo from '../../dashboard/marchants/MarchentInfo'
import VoucherList from '../../dashboard/voucher/VoucherList'
import SalesChart from '../../dashboard/chart/Sales.jsx'
import Card from '@mui/material/Card';
import CardContent from '@mui/material/CardContent';
import { useLocation } from 'react-router-dom';
import { useParams } from "react-router-dom";
import { fetchMerchantsById } from '../../../api/Api.jsx';



export default function MarchantProfile() {


  const [voucher, setVoucher] = useState([]);
  const [loading, setLoading] = useState(true);
  const [error, setError] = useState(null);

  const location = useLocation();

  const merchantData = location.state?.merchant;
  //const vouchersData = merchantData.vouchers;
  const { id } = useParams();

    // ✅ Function to refresh the current merchant using fetchMerchants()
    const refreshMerchant = async () => {
    
      try {
        const token = localStorage.getItem("token");
        const response = await fetchMerchantsById(id,token); 
        const updatedMerchant = response.data[0];
         console.log(updatedMerchant.vouchers)
  
        if (updatedMerchant) {
          setVoucher(updatedMerchant.vouchers); // Update merchant state
        }
      } catch (error) {
        setError(error.message);
      }finally {
        setLoading(false);
      }
    };

    const handleVoucherCreated = () => {
      refreshMerchant();
    };

    
    useEffect(() => {
    
      refreshMerchant();
      }, []);





  return (

    <>
        <div className='bgcolor'>
    <Navbar></Navbar>
    <Box height={40}/>
  <Box sx={{ display: 'flex' }}>
    <Sidebar></Sidebar>
 
    <Box component="main" sx={{ flexGrow: 1, p: 3 }}>
    <Typography
            gutterBottom
            variant="h5"
            component="div"
            sx={{ padding: "15px",fontWeight: 'bold' }}
          >
          Marchent Profile
          </Typography>

          <Grid container rowSpacing={1} columnSpacing={{ xs: 1, sm: 2, md: 3 }}>
  <Grid item xs={6}>
  <Card sx={{ minWidth: 60+"%",height:280 }} >
      
      <CardContent >
  <MarchentInfo
   marchant={merchantData}>
    
   </MarchentInfo>
  </CardContent>
      
      </Card>
  </Grid>
  <Grid item xs={6}>
  <Card sx={{ minWidth: 60+"%",height:280 }} >
      
      <CardContent >
  <SalesChart></SalesChart>
  </CardContent>
      
      </Card>
  </Grid>
  <Grid item xs={12}>
  <VoucherList 
  vouchers={voucher}
  Marchant={merchantData}
  refreshMerchant={handleVoucherCreated}
  ErrorShow={error}
  ShowLoading={loading}
  ></VoucherList>
  </Grid>

</Grid>
       
      </Box>
    </Box>
    </div>
    </>

  )
}
