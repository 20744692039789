import React, { useEffect,useState } from 'react'
import Sidebar from '../../components/Sidebar'
import Box from '@mui/material/Box';
import Navbar from '../../components/Navbar'
import Grid from '@mui/material/Grid';
import Typography from '@mui/material/Typography';
import Card from '@mui/material/Card';
import CardContent from '@mui/material/CardContent';
import Stack from '@mui/material/Stack';
import  '../../assets/css/dashboard.css';
import CountUp from "react-countup";
import RevenueChart from './chart/Revenue';
import TopVoucher from './chart/TopVoucher'
import CustomerSatisfactionChart from './chart/CustomerSatisfaction';
import Swal from "sweetalert2";
import { useNavigate } from "react-router-dom";
import { fetchAdminDashboard } from '../../api/Api';
import Alert from '@mui/material/Alert';
import AlertTitle from '@mui/material/AlertTitle';



export default function Home() {
  const navigate = useNavigate();

  const [data, setData]= useState([]);
  const [error, setError] = useState(null);

   //get admin dashboard data
       const getAdminDashboard = async () => {
        
       
           try {
            const token = localStorage.getItem("token");
            const data = await fetchAdminDashboard(token);
           
            
            setData(data.data); 
           
                 } catch (err) {
                   
                   setError(err.message);
                 }
        
       };


  useEffect(() => {
    // Check if the admin has logged in before
    const SuccessAlert = localStorage.getItem("showSuccessAlert");

    if (SuccessAlert === "true") {
      // Show SweetAlert only if the admin is accessing the dashboard for the first time
      Swal.fire({
        icon: "success",
        title: "Welcome Back, Admin!",
        text: "You are logged in successfully.",
        showConfirmButton: false, // No confirm button
        timer: 1500, 
      }).then(() => {
        localStorage.removeItem("showSuccessAlert");
      });

      // Once the alert is shown, reset the flag to avoid showing the alert again
    
    } 

    getAdminDashboard();
  }, [navigate]);

  return (



    <>
    <div className='bgcolor'>

  
    <Navbar></Navbar>
    <Box height={70}/>
   <Box sx={{ display: 'flex' }}>
    <Sidebar></Sidebar>
 
    <Box component="main" sx={{ flexGrow: 1, p: 3 }}>



    {error ? (
       
          <Alert severity="error" sx={{ mt: 2,pl:3 }}>
            <AlertTitle>Error</AlertTitle>
            There seems to be an issue with fetching dashboard data. Your token may be invalid or there might be a network issue. Please check your connection or log in again.
          </Alert>
        ) : (

          <>
         
         <Grid container spacing={2} >
         <Grid item xs={8}>
        <Stack spacing={2} direction="row">
        <Card sx={{ minWidth: 36.5 +"%",height:90 }} >
      
      <CardContent className="card1gradient">
    
      
        <CountUp end={data.total_users_count} duration={2} style={{ color: "black" ,fontWeight: 'bold',fontSize: '24px'}} />
    
        <Typography gutterBottom variant="body2" component="div" sx={{color:"black",fontWeight: 'bold'}}>
        Total Users
        </Typography>
     
       
      </CardContent>
      
    </Card>
    <Card sx={{ minWidth: 36.5 +"%",height:90 }}>
      
      <CardContent className="card2gradient">
  
    
          <CountUp end={data.total_sell_amount } duration={2} style={{ color: "black" ,fontWeight: 'bold',fontSize: '24px' }} />
      
        <Typography gutterBottom variant="body2" component="div" sx={{color:"black",fontWeight: 'bold'}}>
        Total Sales($)
        </Typography>
      </CardContent>
      
    </Card>
    <Card sx={{ minWidth: 36.5 +"%",height:90 }}>
      
      <CardContent className="card3gradient">
    

        <CountUp end={data.total_redeemed_count} duration={2}  style={{ color: "black",fontWeight: 'bold',fontSize: '24px'  }}/>

        <Typography gutterBottom variant="body2" component="div" sx={{color:"black",fontWeight: 'bold'}}> 
      Redeemed Vouchers
        </Typography>
    
      </CardContent>
      
    </Card>
    <Card sx={{ minWidth: 36.5 +"%",height:90 }}>
      
      <CardContent className="card4gradient">
    
      
        <CountUp end={data.total_remaining_redeem_count} duration={2} style={{ color: "black",fontWeight: 'bold',fontSize: '24px' }}/>
    
        <Typography gutterBottom variant="body2" component="div" sx={{color:"black",fontWeight: 'bold'}}>
        Pendding Redemption
        </Typography>
      
    
      </CardContent>
      
    </Card>
    </Stack>
        </Grid>

       
      </Grid>
     
      <Box height={20}/>
      <Grid container spacing={2}>
        <Grid item xs={12}>
    
      <Grid container spacing={2}>
        <Grid item xs={6}>
        <Card sx={{ height: 35 +"vh" }}>
      
      <CardContent>
        
      <Typography gutterBottom variant="body2" component="div" sx={{color:"black",fontWeight: 'bold'}}>
        Total Revenue
        </Typography>
        <RevenueChart></RevenueChart>
        
      </CardContent>
      
    </Card>
        </Grid>
        <Grid item xs={6}>
        <Card sx={{ height: 35 +"vh" }}>
      
      <CardContent>

      <Typography gutterBottom variant="body2" component="div" sx={{color:"black",fontWeight: 'bold'}}>
        Top Vouchers
        </Typography>
    
        <TopVoucher></TopVoucher>
        
      </CardContent>

    
      
    </Card>
        </Grid>
        <Grid item xs={12}>
        <Card sx={{ height: 38+"vh" }}>
      
      <CardContent>

      <Typography gutterBottom variant="body2" component="div" sx={{color:"black",fontWeight: 'bold'}}>
        Customer Satisfaction
        </Typography>
    
        <CustomerSatisfactionChart></CustomerSatisfactionChart>
        
      </CardContent>

    
      
    </Card>
        </Grid>
      
      </Grid>
  
        </Grid>
      
    
      
      </Grid>
              
      </> 
      )}    
      </Box>
    </Box>
    </div>
    </>
    
  )
}
