import * as React from 'react';
import Paper from '@mui/material/Paper';
import Table from '@mui/material/Table';
import TableBody from '@mui/material/TableBody';
import TableCell from '@mui/material/TableCell';
import TableContainer from '@mui/material/TableContainer';
import TableHead from '@mui/material/TableHead';
import TablePagination from '@mui/material/TablePagination';
import TableRow from '@mui/material/TableRow';
import Typography from "@mui/material/Typography";
import Divider from "@mui/material/Divider";
import Stack from '@mui/material/Stack';
import Autocomplete from "@mui/material/Autocomplete";
import AddCircleIcon from "@mui/icons-material/AddCircle";
import Button from "@mui/material/Button";
import Box from "@mui/material/Box";
import TextField from "@mui/material/TextField";
import  '../../../assets/css/dashboard.css';
import RemoveRedEyeIcon from '@mui/icons-material/RemoveRedEye';
import { useNavigate } from "react-router-dom";
import EditIcon from "@mui/icons-material/Edit";
import DeleteIcon from "@mui/icons-material/Delete";
import Skeleton from '@mui/material/Skeleton';
import Alert from '@mui/material/Alert';
import AlertTitle from '@mui/material/AlertTitle';
import { useEffect, useState } from "react";
import { fetchMerchants } from '../../../api/Api';
import Swal from 'sweetalert2';
import { deleteMarchant } from '../../../api/Api';
import Tooltip from '@mui/material/Tooltip';
import { CircularProgress } from "@mui/material";


export default function Marchantlist() {
  const navigate = useNavigate();
  const [page, setPage] = useState(0);
  const [rowsPerPage, setRowsPerPage] =useState(7);

 // this is for set all marchants data into rows
  const [rows, setRows] = useState([]);
  const [loading, setLoading] = useState(true);
  const [error, setError] = useState(null);
  const [searchInput, setSearchInput] = useState(""); 

  
  const [currentCategory, setCurrentCategory] = useState(null);
  //for search function
  const [allRows, setAllRows] = useState([]); // To store all categories

  //get marchant

    const getMerchants = async () => {
      try {
        const token = localStorage.getItem("token");
        const data = await fetchMerchants(token);
        
  
    
        setRows(data.data); 
        setAllRows(data.data);

      } catch (err) {
        
        setError(err.message);
      } finally {
        setLoading(false);
      }
    };


      useEffect(() => {
        getMerchants();


      }, []);

      //edit marchant

      const handleEdit = (merchant) => {
        navigate(`/marchant/edit/${merchant.ID}`, { state: { merchant } });
      };


  
    
      //vew marchant profile
      const handleView = (merchant) => {
        navigate(`/marchant/profile/${merchant.ID}`, { state: { merchant } });
      };



  if (error) {
    return (
      <Alert severity="error" sx={{ mt: 2 }}>
       <AlertTitle>Error</AlertTitle>
       There seems to be an issue with fetching merchant data. Your token may be invalid or there might be a network issue. Please check your connection or log in again.
       </Alert>
    )
  }


    //delete marchant
    const handleDelete = async (marchantId) => {
      const result = await Swal.fire({
        title: 'Are you sure?',
        text: 'You won\'t be able to revert this!',
        icon: 'warning',
        showCancelButton: true,
        confirmButtonColor: '#3085d6',
        cancelButtonColor: '#d33',
        confirmButtonText: 'Yes, delete it!'
      });
  
      if (result.isConfirmed) {
        try {
          const token = localStorage.getItem("token");
          await deleteMarchant(marchantId,token);

          Swal.fire('Deleted!', 'The marchant has been deleted.', 'success');
          getMerchants();
        } catch (err) {
          Swal.fire('Error!', 'Failed to delete the marchant.', 'error');
        }
      }
    };

  const handleChangePage = (event, newPage) => {
    setPage(newPage);
  };

  const handleChangeRowsPerPage = (event) => {
    setRowsPerPage(+event.target.value);
    setPage(0);
  };




//handel search
const handleSearchInputChange = (event, value) => {
  setSearchInput(value); // Update input value

  if (value) {
    const filteredData = allRows.filter((row) =>
      row.country.toLowerCase().includes(value.toLowerCase())
    );
    setRows(filteredData); // Set filtered rows
  } else {
    setRows(allRows); // Reset to all data if input is empty
  }
};





  return (
    <Paper sx={{ width: '100%', overflow: 'hidden' }}>

<Typography
            gutterBottom
            variant="h5"
            component="div"
            sx={{ padding: "10px",fontWeight: 'bold' }}
          >
            Marchants List
          </Typography>
          <Divider />
          <Box height={10} />
          <Box height={10} />
          <Stack direction="row" spacing={2} className="my-2 mb-2" sx={{ padding: '10px' }} >
                <Autocomplete
                      
                      disablePortal
                                  id="combo-box-demo"
                                  options={rows}
                                  sx={{ width: 300 }}
                                  Value={searchInput}
                                  onInputChange={handleSearchInputChange}
                                  getOptionLabel={(rows) => rows.country || ""}
                                  renderInput={(params) => (
                                    <TextField {...params} size="small" label="Search Marchants" />
                                  )}
                      />
            <Typography
              variant="h6"
              component="div"
              sx={{ flexGrow: 1}}
            ></Typography>
            <Button variant="contained" endIcon={<AddCircleIcon />}  sx={{
        backgroundColor: "gray", // Gray background color
        color: "white", // Text color (optional for contrast)
        "&:hover": {
          backgroundColor: "#6c757d", // Slightly darker gray for hover effect
        }
      }}  onClick={()=>{navigate('/marchant/add')}} >
              Add New Marchant
            </Button>
          </Stack>
          <Box height={10} />
      <TableContainer sx={{ maxHeight: 600 }}>
        <Table stickyHeader aria-label="sticky table">
          <TableHead>
          <TableRow>
                  <TableCell align="left" style={{ minWidth: "100px",fontWeight: 'bold' }}>
                   Logo
                  </TableCell>
                  <TableCell align="left" style={{ minWidth: "100px",fontWeight: 'bold' }}>
                    Shop Name
                  </TableCell>
                  <TableCell align="left" style={{ minWidth: "100px",fontWeight: 'bold' }}>
                   Name
                  </TableCell>
                  <TableCell align="left" style={{ minWidth: "100px",fontWeight: 'bold' }}>
                    Address
                  </TableCell>
                  <TableCell align="left" style={{ minWidth: "100px",fontWeight: 'bold' }}>
                   Phone
                  </TableCell>
                  <TableCell align="left" style={{ minWidth: "100px",fontWeight: 'bold' }}>
                   Country
                  </TableCell>
                  <TableCell align="left" style={{ minWidth: "100px",fontWeight: 'bold' }}>
                    Action
                  </TableCell>
                </TableRow>
          </TableHead>
          <TableBody>
          {loading ? (
    
    <TableRow>
<TableCell colSpan={7} align="center">
<Box display="flex" flexDirection="column" alignItems="center" justifyContent="center" py={5}>
          <CircularProgress size={50} thickness={4.5} sx={{ color: "blue" }} />
          <Typography variant="body1" mt={2} fontWeight="bold" color="text.secondary">
            Loading data, please wait...
          </Typography>
        </Box>
</TableCell>
</TableRow>




) : (
          rows
                  .slice(page * rowsPerPage, page * rowsPerPage + rowsPerPage)
                  .map((row) => {
                    return (
                      <TableRow
                        hover
                        role="checkbox"
                        tabIndex={-1}
                        key={row.code}
                      >
                          <TableCell align="left">
                          <div>
                  <img
                    src={row.brand_logo}// Replace with your logo URL
                    alt="Brand Logo"
                    style={{
                      width: "35px", // Set the size of the image
                      height: "35px",
                      borderRadius: "50%", // Makes the image circular
                      objectFit: "cover", // Ensures the image covers the area
                    }}
                  />
                </div>
                          </TableCell>
                        <TableCell align="left">{row.shop_name}</TableCell>
                        <TableCell align="left">{row.name}</TableCell>
                        <TableCell align="left">{row.address}</TableCell>
                        <TableCell align="left">{row.phone}</TableCell>
                        <TableCell align="left">{row.country}</TableCell>
                        <TableCell align="left">
                          <Stack spacing={1} direction="row">
                            <Tooltip title="Edit">
                          <EditIcon
                              style={{
                                fontSize: "20px",
                                color: "blue",
                                cursor: "pointer",
                                
                              }}
                              className="cursor-pointer"
                              onClick={() => handleEdit(row)}
                        
                            />
                                  </Tooltip>
                                  <Tooltip title="Delete">
                            <DeleteIcon
                              style={{
                                fontSize: "20px",
                                color: "darkred",
                                cursor: "pointer",
                              }}
                              onClick={() =>  handleDelete(row.ID)}
                            />
                              </Tooltip>
                              <Tooltip title="View">
                            <RemoveRedEyeIcon
                              style={{
                                fontSize: "20px",
                                color: "darkred",
                                cursor: "pointer",
                              }}
                              onClick={() => handleView(row)}

                            />
  </Tooltip>
                          </Stack>
                        </TableCell>
                      </TableRow>
                    );
                  })
                )}
          </TableBody>
        </Table>
      </TableContainer>
      <TablePagination
        rowsPerPageOptions={[10, 25, 100]}
        component="div"
        count={rows.length}
        rowsPerPage={rowsPerPage}
        page={page}
        onPageChange={handleChangePage}
        onRowsPerPageChange={handleChangeRowsPerPage}
      />
    </Paper>
  );
}