// src/components/MerchantForm.js
import React, { useState,useEffect } from 'react'
import Swal from "sweetalert2"; 
import '../../../assets/css/OnbordNewMarchent.css'
import CircularProgress from '@mui/material/CircularProgress';
import Alert from '@mui/material/Alert';
import AlertTitle from '@mui/material/AlertTitle';
import { useLocation } from 'react-router-dom';
import { fetchCountry, updateMerchant, uploadLogo } from "../../../api/Api";

const UpdateMarchentForm = () => {
const location = useLocation();

const merchantData = location.state?.merchant;
const [formData, setFormData] = useState({
    merchantName: merchantData.name || "",
    shopName: merchantData.shop_name || "",
    phoneNumber: merchantData.phone || "",
    address: merchantData.address || "",
    country: merchantData.country || "",
    latitude: merchantData.latitude || "",
    longitude: merchantData.longitude || "",
    logo: null,
    brand_logo: merchantData.brand_logo || "",
    hours: merchantData.shop_schedule
      ? merchantData.shop_schedule.reduce((acc, schedule) => {
          acc[schedule.day_of_week.toLowerCase()] = {
            open: schedule.opening_time || "",
            close: schedule.closing_time || "",
            isClosed: schedule.is_closed || false,
          };
          return acc;
        }, {})
      : {
          monday: { open: "", close: "", isClosed: false },
          tuesday: { open: "", close: "", isClosed: false },
          wednesday: { open: "", close: "", isClosed: false },
          thursday: { open: "", close: "", isClosed: false },
          friday: { open: "", close: "", isClosed: false },
          saturday: { open: "", close: "", isClosed: false },
          sunday: { open: "", close: "", isClosed: true },
        },
  });
  const [loading, setLoading] = useState(false);
    //get county
    const [country, setCountry] = useState([]);
    const [countryError, setCountryError] = useState("");



    const getCountry = async () => {
      try {
        const token = localStorage.getItem("token");
        const data = await fetchCountry(token);
  
  
        setCountry(data.data); 
  
    
      } catch (err) {
        setCountryError(err.message);
      } 
    };


      useEffect(() => {
        getCountry();
  
      }, []);


  const handleInputChange = (e) => {
    const { name, value } = e.target;
    setFormData((prevData) => ({
      ...prevData,
      [name]: value,
    }));
  };
  const handleTimeChange = (day, type, value) => {
    setFormData((prevData) => ({
      ...prevData,
      hours: {
        ...prevData.hours,
        [day]: {
          ...prevData.hours[day],
          [type]: value,
        },
      },
    }));
  };

  const handleIsClosedChange = (day, isClosed) => {
    setFormData((prevData) => ({
      ...prevData,
      hours: {
        ...prevData.hours,
        [day]: {
          open: "",
          close: "",
          isClosed,
        },
      },
    }));
  };
  // upload logo
  const handleFileChange = (e) => {
    const file = e.target.files[0];
    if (file) {
      setFormData((prevData) => ({
        ...prevData,
        logo: file, // Store the file object in the formData state
      }));
    }
  };

 // update marchent
 const handleSubmit = async (e) => {
    e.preventDefault();
    setLoading(true);
  
    try {
      let logoUrl = formData.brand_logo; // Use existing logo if no new file is selected
  
      if (formData.logo) {
        // Upload new logo
        const uploadResponse = await uploadLogo(formData.logo);
        if (uploadResponse.success && uploadResponse.fileUrl) {
          logoUrl = uploadResponse.fileUrl;
    
        } else {
         
            Swal.fire({
                icon: "error",
                      title: "Failed to Upload logo.",
                      toast: true,
                      position: "top-right",      
                      showConfirmButton: false,
                      timer: 1500,
                      timerProgressBar: true,
                      background: "linear-gradient(to right, red, red)",
                      color: "#fff",
                      iconColor: "#fff",
                      customClass: {
                        popup: "premium-toast-popup",
                        container: "swal-toast-container",
                      },
              });
          setLoading(false);
          return;
        }
      }
  
      const updatedData = {
        name: formData.merchantName,
        phone: formData.phoneNumber,
        latitude: parseFloat(formData.latitude),
        longitude: parseFloat(formData.longitude),
        address: formData.address,
        shop_name: formData.shopName,
        country: formData.country,
        brand_logo: logoUrl, // Updated logo URL
        shop_schedule: Object.keys(formData.hours).map((day) => ({
          day_of_week: day.charAt(0).toUpperCase() + day.slice(1),
          opening_time: formData.hours[day].open || null,
          closing_time: formData.hours[day].close || null,
          is_closed: formData.hours[day].isClosed,
        })),
      };
    
      const token = localStorage.getItem("token");
      const response = await updateMerchant(merchantData.ID, updatedData, token);
  
      if (response.status_code===200) {
        Swal.fire({
            icon: "success",
            title: "Merchant updated successfully!",
            toast: true,
            position: "top-right",
            showConfirmButton: false,
            timer: 1000,
            timerProgressBar: true,
            background: "linear-gradient(to right, #008000, #32CD32)", // Green gradient success
            color: "#fff",
            iconColor: "#fff",
            customClass: {
              popup: "premium-toast-popup",
              container: "swal-toast-container",
            },
          })
          setLoading(false);
      } else {
        Swal.fire({
            icon: "error",
            title: "Failed to Update Marchant. Please try again.",
            toast: true,
            position: "top-right",
            showConfirmButton: false,
            timer: 1500,
            timerProgressBar: true,
            background: "linear-gradient(to right, red, red)",
            color: "#fff",
            iconColor: "#fff",
            customClass: {
              popup: "premium-toast-popup",
              container: "swal-toast-container",
            },
          });
      }
    } catch (error) {

      Swal.fire({
        icon: "error",
              title: "An error occurred. Please try again.",
              toast: true,
              position: "top-right",
              
              showConfirmButton: false,
              timer: 1500,
              timerProgressBar: true,
              background: "linear-gradient(to right, red, red)",
              color: "#fff",
              iconColor: "#fff",
              customClass: {
                popup: "premium-toast-popup",
                container: "swal-toast-container",
              },
      });
    } finally {
      setLoading(false);
    }
  };
  
  
  if (!merchantData) {
        
    return (
      <Alert severity="error" sx={{ mt: 2 }}>
           <AlertTitle>Error</AlertTitle>
           Error: No merchant data provided.
           </Alert>
    )
        
}
        

  return (
    <div className="merchant-form">
      <form onSubmit={handleSubmit}>
        {/* Merchant Name, Shop Name, Phone Number, Address */}
        <div className="form-row">
          <div className="form-column">
            <label>Merchant Name</label>
            <input
              type="text"
              name="merchantName"
              value={formData.merchantName}
              onChange={handleInputChange}
              required
            />
          </div>
          <div className="form-column">
            <label>Shop Name</label>
            <input
              type="text"
              name="shopName"
              value={formData.shopName}
              onChange={handleInputChange}
              required
            />
          </div>
          <div className="form-column">
            <label>Phone Number</label>
            <input
              type="text"
              name="phoneNumber"
              value={formData.phoneNumber}
              onChange={handleInputChange}
              required
            />
          </div>
          <div className="form-column">
            <label>Address</label>
            <input
              type="text"
              name="address"
              value={formData.address}
              onChange={handleInputChange}
              required
            />
          </div>
        </div>

        {/* Country, Latitude, Longitude, Brand Logo */}
        <div className="form-row">
          <div className="form-column">
            <label>Country</label>
          
                    <select
                        type="text"
              name="country"
              value={formData.country}
              onChange={handleInputChange}
              required
            >
           <option value="">Select Country</option>
            {country.map((row) => {

              return(
            
              <option key={row.ID} value={row.country_name}> 
                             {row.country_name}
              </option>
              )
            })

            }
            
            </select>
          
            {countryError ?  <Alert severity="error" sx={{ mt: 2 }}>
                     <AlertTitle>Error</AlertTitle>
                       There was an issue fetching the country data..
                     </Alert>
                             : ""}
          </div>
          <div className="form-column">
            <label>Latitude</label>
            <input
              type="text"
              name="latitude"
              value={formData.latitude}
              onChange={handleInputChange}
              required
            />
          </div>
          <div className="form-column">
            <label>Longitude</label>
            <input
              type="text"
              name="longitude"
              value={formData.longitude}
              onChange={handleInputChange}
              required
            />
          </div>
          <div className="form-row">
  <label>Brand Logo</label>
  <input
    type="file"
    name="logo"
    accept="image/*"
    onChange={handleFileChange}
  />
  {formData.brand_logo && !formData.logo && (
    <div className="logo-preview">
      <p>Current Logo:</p>
      <img src={formData.brand_logo} alt="Brand Logo" style={{ width: "100px", height: "auto" }} />
    </div>
  )}
  {formData.logo && (
    <div className="logo-preview">
      <p>Selected Logo:</p>
      <p>{formData.logo.name}</p>
    </div>
  )}
</div>
        </div>


        {/* Time Management Section */}
        <div className="time-management">
        <label   style={{
              fontweight: "bold",
              color:"black",
    
            }}>Time Management:</label>
          {Object.keys(formData.hours).map((day) => (
            <div key={day} className="form-row">
              <div className="form-column">
                <label>{day.charAt(0).toUpperCase() + day.slice(1)} Open</label>
                <input
                  type="time"
                  value={formData.hours[day].open}
                  onChange={(e) =>
                    handleTimeChange(day, "open", e.target.value)
                  }
                  disabled={formData.hours[day].isClosed}
                  required={!formData.hours[day].isClosed}
                />
              </div>
              <div className="form-column">
                <label>{day.charAt(0).toUpperCase() + day.slice(1)} Close</label>
                <input
                  type="time"
                  value={formData.hours[day].close}
                  onChange={(e) =>
                    handleTimeChange(day, "close", e.target.value)
                  }
                  disabled={formData.hours[day].isClosed}
                  required={!formData.hours[day].isClosed}
                />
              </div>
              <div className="form-column">
                <label>
                  <input
                    type="checkbox"
                    checked={formData.hours[day].isClosed}
                    onChange={(e) =>
                      handleIsClosedChange(day, e.target.checked)
                    }
                  />
                  Closed
                </label>
              </div>
            </div>
          ))}
        </div>

        <div>
          <button type="submit" className='submitButton'  style={{
              display: "block",
              marginBottom: "5px",
        
              fontWeight: "bold",
            }}> {loading ? <CircularProgress
                               size={16}
                               sx={{
                               
                                 color: 'inherit',
                               }}
                             /> : "Submit"}</button>
        </div>
      </form>
    </div>
  );
};

export default UpdateMarchentForm;
