import React from "react";
import { Chart as ChartJS, CategoryScale, LinearScale, BarElement, LineElement, Title, Tooltip, Legend } from "chart.js";
import { Chart } from "react-chartjs-2";

// Register Chart.js components
ChartJS.register(CategoryScale, LinearScale, BarElement, LineElement, Title, Tooltip, Legend);

const TopVoucher = () => {
  // Sample data for Top 5 Vouchers
  const data = {
    labels: ["Voucher A", "Voucher B", "Voucher C", "Voucher D", "Voucher E"], // Voucher names
    datasets: [
      {
        type: "line", // Popularity as a line chart
        label: "Popularity %",
        data: [85, 90, 70, 60, 75], // Popularity scores
        borderColor: "rgba(75, 192, 192, 1)", // Line color
        borderWidth: 2,
        tension: 0.4, // Smooth curve
        fill: false,
      },
      {
        type: "bar", // Sales % as a bar chart
        label: "Sales %",
        data: [55, 65, 45, 50, 40], // Sales percentages
        backgroundColor: "rgba(255, 99, 132, 0.6)", // Bar color
        borderWidth: 1,
      },
    ],
  };

  const options = {
    responsive: true,
    maintainAspectRatio: false, // Allows resizing
    indexAxis: "y", // Horizontal chart
    plugins: {
      legend: {
        position: "top",
      },
     
    },
    scales: {
      x: {
        beginAtZero: true,
        title: {
          display: true,
          text: "Percentage (%)",
        },
      },
      y: {
        title: {
          display: true,
          text: "Vouchers",
        },
      },
    },
  };

  return (
    <div
      style={{
        width: "30vw", // Smaller width (30% of the viewport)
        height: "30vh", // Smaller height (35% of the viewport)
        margin: "0 auto",
      }}
    >
      <Chart type="bar" data={data} options={options} />
    </div>
  );
};

export default TopVoucher;
