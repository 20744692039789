import React, { useState } from 'react'
import  '../assets/css/login.css';

import { useNavigate } from "react-router-dom";
import Swal from "sweetalert2";
import { BASE_URL } from '../App';

// import { BASE_URL } from "../../public/config";


export default function AdminLogin() {

             
    const [username, setUserName] = useState("");
    const [password, setPassword] = useState("");
    const navigate = useNavigate();

    const formData = new FormData();
    formData.append("username", username);
    formData.append("password", password);


const handleSubmit = async (e) => {
    e.preventDefault();



    try {
      const response = await fetch(`${BASE_URL}system-login`, {
        method: "POST",
        body: formData,
      });
      
   console.log("===Login Response===",response);
  if (response.status==401) {
    Swal.fire({
      icon: "error", 
      title: "Login Failed",
      text: "Invalid username or password.", 
      toast: true, 
      position: "top", 
      showConfirmButton: false, 
      timer: 2000,
      timerProgressBar: true, 
      background: "linear-gradient(to right, red, #feb47b)", 
      color: "#fff",
      iconColor: "#fff",
      customClass: {
        popup: "premium-toast-popup",
      }
    }).then(() => {
      // setUserName(""); // Clear username field
      // setPassword(""); // Clear password field
    

    });
  }else if (!response.ok){
    Swal.fire({
      icon: "error", 
      title: "Login Failed",
      text: "An error occurred. Please check your internet connection.", 
      toast: true, 
      position: "top", 
      showConfirmButton: false, 
      timer: 2000,
      timerProgressBar: true, 
      background: "linear-gradient(to right, red, #feb47b)", 
      color: "#fff",
      iconColor: "#fff",
      customClass: {
        popup: "premium-toast-popup",
      }
    }).then(() => {
      // setUserName(""); // Clear username field
      // setPassword(""); // Clear password field
    

    });
  }
    
    else{
    // login successfully
    const data = await response.json();
    const token =data.token;

  
    localStorage.setItem("userItem", JSON.stringify(data));
    localStorage.setItem("hasLoggedIn", "true");
    localStorage.setItem("showSuccessAlert", "true");
    localStorage.setItem("token", token);
    navigate("/"); // Redirect to the dashboard after success



  }



    } catch (error) {
      console.log("===Login Error===",error);
      
      Swal.fire({
        icon: "error", 
        text: "An error occurred. Please try again", 
        toast: true, 
        position: "top", 
        showConfirmButton: false, 
        timer: 2000,
        timerProgressBar: true, 
        background: "linear-gradient(to right, red, #feb47b)", 
        color: "#fff",
        iconColor: "#fff",
        customClass: {
          popup: "premium-toast-popup",
        }
      }).then(() => {
        // setUserName(""); // Clear username field
        // setPassword(""); // Clear password field

      });
    }

  };

  return (
    <div class="login-page">
         <div class="login-container">
        <div class="login-header">
            <h1>WEQUI</h1>
            <h4>Plese Enter your login credentials</h4>
            
        </div>
        <form class="login-form" id="loginForm" onSubmit={handleSubmit}>
            <div class="form-group">
                <label for="username">UserName</label>
                <input 
                    type="text" 
                    id="username"
                    value={username}
                    onChange={(e) => setUserName(e.target.value)}
                    required 
                    placeholder="Enter your UserName"
                ></input>
              
            </div>
            <div class="form-group">
                <label for="password">Password</label>
                <input 
                    type="password" 
                    id="password"
                    value={password}
                    onChange={(e) => setPassword(e.target.value)}
                    required 
                    placeholder="Enter your password"
                
                ></input>
                
            </div>
            <button type="submit" class="login-button">Login</button>
        
        
        </form>
    
    </div>
    </div>
  )
}