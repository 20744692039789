import React from "react";
import { Bar } from "react-chartjs-2";
import {
  Chart as ChartJS,
  CategoryScale,
  LinearScale,
  BarElement,
  Title,
  Tooltip,
  Legend,
} from "chart.js";

// Register necessary components for Chart.js
ChartJS.register(CategoryScale, LinearScale, BarElement, Title, Tooltip, Legend);

const Revenue = () => {
  // Sample data
  const data = {
    labels: ["2019", "2020", "2021", "2022", "2023"], // Years
    datasets: [
      {
        label: "Total Sales ($)",
        data: [50000, 70000, 60000, 80000, 90000], // Total Sales by year
        backgroundColor: "rgba(75, 192, 192, 0.6)", // Color for sales
      },
      {
        label: "Total Expenses ($)",
        data: [30000, 40000, 35000, 45000, 50000], // Total Expenses by year
        backgroundColor: "rgba(255, 99, 132, 0.6)", // Color for expenses
      },
      {
        label: "Total Revenue ($)",
        data: [20000, 30000, 25000, 35000, 40000], // Total Revenue by year
        backgroundColor: "rgba(54, 162, 235, 0.6)", // Color for revenue
      },
    ],
  };

  const options = {
    responsive: true,
    maintainAspectRatio: false, // Disable automatic aspect ratio
    plugins: {
      legend: {
        position: "top", // Legend position
      },
      title: {
        display: true,
        text: "Company Performance (2019-2023)",
      },
    },
    scales: {
      y: {
        beginAtZero: true,
        title: {
          display: true,
          text: "Amount ($)",
        },
      },
      x: {
        title: {
          display: true,
          text: "Year",
        },
      },
    },
  };

  return (
    <div
      style={{
        width: "30vw", // Set width to 30% of the viewport width
        height: "30vh", // Set height to 35% of the viewport height
        margin: "0 auto",
      }}
    >
      <Bar data={data} options={options} />
    </div>
  );
};

export default Revenue;
