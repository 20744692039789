import React from "react";
import { Navigate } from "react-router-dom";

// A Protected Route component to handle authentication check
const AuthGuard = ({ children }) => {
  // Check if the user is logged in by reading the flag from localStorage
  const isLoggedIn = localStorage.getItem("hasLoggedIn");
  const unauthorized = localStorage.getItem("unauthorized");

  // If not logged in, redirect to the login page
  if (!isLoggedIn )  {
    return <Navigate to="/dashboard/login" />;
  }

  // If logged in, allow access to the route
  return children;
};

export default AuthGuard;
