// src/components/MerchantProfile.js
// src/components/MerchantProfile.js
import React from 'react';
import '../../../assets/css/marchentProfile.css'
import Typography from "@mui/material/Typography";


const MerchantProfile = ({marchant}) => {


  // Example merchant data (replace with actual data)
  const merchantData = {
    name: '',
    phone:'',
    shopName: '',
    address: '',
    country: '',
    createdAt: '',
    image: 'https://stat5.bollywoodhungama.in/wp-content/uploads/2016/02/salman-Khan-header-1.jpg' // Replace with a real image URL
  };


  //update marchantDate 
  merchantData.name = marchant.name || "";
  merchantData.phone = marchant.phone || "";
  merchantData.shopName = marchant.shop_name || "";
  merchantData.address = marchant.address || "";
  merchantData.country = marchant.country || "";
  merchantData.createdAt = marchant.CreatedAt || ""; 

  // Format the date to "DD Month YYYY"
const formattedDate = new Date(merchantData.createdAt).toLocaleDateString("en-US", {
  day: "numeric",
  month: "long",
  year: "numeric",
});


  return (
    
    <div className="merchant-profile">
    
      <div className="profile-image-container">
        <img
          src={merchantData.image}
          alt={`${merchantData.name}'s Profile`}
          className="profile-image"
        />
      </div>
      <div className="profile-info">
        <h1 className="merchant-name">{merchantData.name}</h1>
        <span className="title"> {merchantData.phone}</span>
        <div className="details">
          <p>
            <span className="title">Shop Name:</span> {merchantData.shopName}
          </p>
          <p>
            <span className="title">Address:</span> {merchantData.address}
          </p>
          <p>
            <span className="title">Country:</span> {merchantData.country}
          </p>
          <p>
            <span className="title">Created At:</span> {formattedDate}
          </p>
        </div>
      </div>
    </div>
  );
};

export default MerchantProfile;

