import React from 'react'
import Sidebar from '../../components/Sidebar'
import Box from '@mui/material/Box';
import Navbar from '../../components/Navbar';
import OnGoingTeamslist from './OnGoingTeamsList';


export default function OnGoingTeams() {
  return (

    <>

<div className='bgcolor'>
     <Navbar></Navbar>
     <Box height={70}/>
  <Box sx={{ display: 'flex' }}>
    <Sidebar></Sidebar>
 
    <Box component="main" sx={{ flexGrow: 1, p: 3 }}>
    <OnGoingTeamslist></OnGoingTeamslist>
       
      </Box>
    </Box>
    </div>
    </>

  )
}