import React, { useState } from "react";
import { Box, Tabs, Tab, Typography, TextField, Button } from "@mui/material";
import AdminProfile from "./AdminProfile";
import ChangePassword from "./ChangePassword";

const SettingTab = () => {
  const [value, setValue] = useState(0);

  const handleTabChange = (event, newValue) => {
    setValue(newValue);
  };

  const TabPanel = ({ children, value, index }) => {
    return (
      <div
        role="tabpanel"
        hidden={value !== index}
        style={{ marginTop: "10px" }}
      >
        {value === index && <Box>{children}</Box>}
      </div>
    );
  };

  return (
    <Box sx={{ width: "100%", maxWidth: "600px", mx: "auto", mt: 4 }}>
      <Tabs
        value={value}
        onChange={handleTabChange}
        centered
        textColor="primary"
        indicatorColor="primary"
      >
        <Tab label="Profile" />
        <Tab label="Change Password" />
      </Tabs>

      {/* Profile Tab */}
      <TabPanel value={value} index={0}>
        <Typography variant="h6" gutterBottom>
          Profile Information
        </Typography>
            <AdminProfile></AdminProfile>
      </TabPanel>

      {/* Change Password Tab */}
      <TabPanel value={value} index={1}>
        <ChangePassword></ChangePassword>
      </TabPanel>
    </Box>
  );
};

export default SettingTab;
