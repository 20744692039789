import React from "react";
import { Box, Typography, Button } from "@mui/material";
import { useNavigate } from "react-router-dom";

const PageNotFound = () => {
  const navigate = useNavigate();

  const handleGoHome = () => {
    navigate("/");
  };

  return (
    <Box
      sx={{
        height: "100vh",
        display: "flex",
        flexDirection: "column",
        justifyContent: "center",
        alignItems: "center",
        textAlign: "center",
        backgroundColor: "#f7f9fc",
        p: 3,
      }}
    >
      <Typography
        variant="h1"
        sx={{
          fontSize: "5rem",
          fontWeight: "bold",
          color: "#1976d2",
          mb: 2,
        }}
      >
        404
      </Typography>
      <Typography
        variant="h5"
        sx={{
          color: "text.secondary",
          mb: 3,
        }}
      >
        Oops! The page you are looking for doesn't exist.
      </Typography>
      <Button
        variant="contained"
        color="primary"
        size="large"
        onClick={handleGoHome}
        sx={{
          px: 5,
          py: 1.5,
          fontSize: "1rem",
          fontWeight: "bold",
          textTransform: "none",
        }}
      >
        Go to Home
      </Button>
    </Box>
  );
};

export default PageNotFound;
