import React from "react";
import { Box, Typography, Grid, Card, Avatar, Button } from "@mui/material";



const AdminProfile = () => {

  // Retrieve userItem data from localStorage
const userItem = localStorage.getItem("userItem");




  const adminData = {
    name: "",
    email: "",
    phone: "",
    address: "",
    dateOfBirth: "",
    joinedAt:"",
    profileImage: "https://scontent.fdac177-1.fna.fbcdn.net/v/t39.30808-1/448810691_3689706504690951_8593861968090270911_n.jpg?stp=dst-jpg_s160x160_tt6&_nc_cat=100&ccb=1-7&_nc_sid=fe756c&_nc_ohc=5QGzyRsZkukQ7kNvgEtOkAc&_nc_zt=24&_nc_ht=scontent.fdac177-1.fna&_nc_gid=AT7qO7qTgehMXUkUGgIKzBu&oh=00_AYCgSHW4T1HLUux3CBuHQxPBr3ltEypmum-wEv92Y5mztQ&oe=6789BDA2", // Replace with a real image URL
  };

  // Update adminData with retrieved userItem data if it exists
if (userItem) {
  try {
    const parsedData = JSON.parse(userItem); // Parse the JSON string into an object

    // Check if parsedData contains the expected structure
    if (parsedData.data) {
      adminData.name = parsedData.data.name || "";
      adminData.email = parsedData.data.email || "";
      adminData.address = parsedData.data.address || "";
      adminData.phone = parsedData.data.phone || "";
      adminData.dateOfBirth = parsedData.data.date_of_birth || "";
      adminData.joinedAt = parsedData.data.CreatedAt || "";

    }
  } catch (error) {
    alert("Failed to parse userItem data from localStorage:", error);
  }
}

// Format the date to "DD Month YYYY"
const formattedDate = new Date(adminData.joinedAt).toLocaleDateString("en-US", {
  day: "numeric",
  month: "long",
  year: "numeric",
});

  return (
    <Box sx={{ p: 1, maxWidth: "800px", mx: "auto" }}>
      {/* Profile Card */}
      <Card
        sx={{
          p: 1,
          textAlign: "center",
          boxShadow: 3,
          borderRadius: 3,
        }}
      >
        <Avatar
          src={adminData.profileImage}
          alt={adminData.name}
          sx={{ width: 120, height: 120, mx: "auto", mb: 2 }}
        />
        <Typography variant="h5" sx={{ fontWeight: "bold" }}>
          {adminData.name}
        </Typography>
      

      </Card>

      {/* Details Section */}
      <Box
        sx={{
          mt: 4,
          p: 1,
          borderRadius: 3,
          boxShadow: 2,
          backgroundColor: "#f9f9f9",
        }}
      >
        <Typography variant="h6" gutterBottom>
          Admin Information
        </Typography>
        <Grid container spacing={2}>
          {/* Email */}
          <Grid item xs={12} sm={6}>
            <Typography variant="subtitle2" color="text.secondary">
              Email
            </Typography>
            <Typography variant="body1">{adminData.email}</Typography>
          </Grid>
          {/* Phone */}
          <Grid item xs={12} sm={6}>
            <Typography variant="subtitle2" color="text.secondary">
              Phone
            </Typography>
            <Typography variant="body1">{adminData.phone}</Typography>
          </Grid>
          {/* Address */}
          <Grid item xs={12} sm={6}>
            <Typography variant="subtitle2" color="text.secondary">
              Address
            </Typography>
            <Typography variant="body1">{adminData.address}</Typography>
          </Grid>
          {/* Country */}
          <Grid item xs={12} sm={6}>
            <Typography variant="subtitle2" color="text.secondary">
              Date Of Birth
            </Typography>
            <Typography variant="body1">{adminData.dateOfBirth}</Typography>
          </Grid>
          {/* Joined At */}
          <Grid item xs={16}>
            <Typography variant="subtitle2" color="text.secondary">
              Joined At
            </Typography>
            <Typography variant="body1">{formattedDate}</Typography>
          </Grid>
         
        </Grid>
      </Box>
    </Box>
  );
};

export default AdminProfile;
