import React, { useState,useEffect } from 'react'
import Paper from '@mui/material/Paper';
import Table from '@mui/material/Table';
import TableBody from '@mui/material/TableBody';
import TableCell from '@mui/material/TableCell';
import TableContainer from '@mui/material/TableContainer';
import TableHead from '@mui/material/TableHead';
import TablePagination from '@mui/material/TablePagination';
import TableRow from '@mui/material/TableRow';
import Typography from "@mui/material/Typography";
import Divider from "@mui/material/Divider";
import Stack from '@mui/material/Stack';
import AddCircleIcon from "@mui/icons-material/AddCircle";
import Button from "@mui/material/Button";
import Box from "@mui/material/Box";
import  '../../../assets/css/dashboard.css';
import EditIcon from "@mui/icons-material/Edit";
import DeleteIcon from "@mui/icons-material/Delete";
import Tooltip from '@mui/material/Tooltip';
import Modal from '@mui/material/Modal';
import VoucherForm from '../../dashboard/voucher/VoucherForm'
import Skeleton from '@mui/material/Skeleton';
import Alert from '@mui/material/Alert';
import AlertTitle from '@mui/material/AlertTitle';
import Swal from 'sweetalert2';
import { deleteVoucher } from '../../../api/Api';
import EditVoucher from './EditVoucher';
import { CircularProgress } from "@mui/material";



const style = {
  position: 'absolute',
  top: '50%',
  left: '50%',
  transform: 'translate(-50%, -50%)',
  width: 1000,
  bgcolor: 'background.paper',
  border: '2px solid #000',
  boxShadow: 24,
  p: 4,
};



export default function VoucherList({vouchers,Marchant,refreshMerchant,ErrorShow,ShowLoading}) {
  const [page, setPage] = useState(0);
  const [rowsPerPage, setRowsPerPage] = useState(4);
  const [rows, setRows] = useState([]); // store voucher data



        //for modal
        const [open, setOpen] = React.useState(false);
        const handleOpen = () => setOpen(true);
        const handleClose = () => setOpen(false);

        useEffect(() => {
          // Initialize rows with the vouchers prop
          setRows(vouchers);
        }, [vouchers]);
  const handleChangePage = (event, newPage) => {
    setPage(newPage);
  };

      const [currentVoucher, setCurrentVoucher] = useState(null);
//for edit model

         const [EditOpen, setEditOpen] = React.useState(false);
         const EditHandleOpen = (voucher) => {
          setCurrentVoucher(voucher);
           
           setEditOpen(true);
     
         }
         const EditHandleClose = () => {
          setCurrentVoucher(null);
          setEditOpen(false);
          }

  const handleChangeRowsPerPage = (event) => {
    setRowsPerPage(+event.target.value);
    setPage(0);
  };


  
      //delete voucher
      const handleDelete = async (voucherId) => {
        const result = await Swal.fire({
          title: 'Are you sure?',
          text: 'You won\'t be able to revert this!',
          icon: 'warning',
          showCancelButton: true,
          confirmButtonColor: '#3085d6',
          cancelButtonColor: '#d33',
          confirmButtonText: 'Yes, delete it!'
        });
    
        if (result.isConfirmed) {
          try {
            const token = localStorage.getItem("token");
            await deleteVoucher(voucherId,token);
  
            Swal.fire('Deleted!', 'The Voucher has been deleted.', 'success');  
            if (refreshMerchant) refreshMerchant(); 
          } catch (err) {
            Swal.fire('Error!', 'Failed to delete the Voucher.', 'error');
          }
        }
      };



if (ErrorShow) {
    return (
      <Alert severity="error" sx={{ mt: 2 }}>
       <AlertTitle>Error</AlertTitle>
       There seems to be an issue with fetching voucher data. Your token may be invalid or there might be a network issue. Please check your connection or log in again.
       </Alert>
    )
  }


  return (
    <Paper sx={{ width: '100%', overflow: 'hidden' }}>
<div>
     
     <Modal
       open={open}
       
       aria-labelledby="modal-modal-title"
       aria-describedby="modal-modal-description"
     >
       <Box sx={style}>
            <VoucherForm 
            CloseEvent={handleClose}
            MarchantData={Marchant}
            onSuccess={refreshMerchant}
        
            />
       </Box>
     </Modal>
   </div>
{/* 
   edit model */}
   <div>
     
     <Modal
       open={EditOpen}
       
       aria-labelledby="modal-modal-title"
       aria-describedby="modal-modal-description"
     >
       <Box sx={style}>
            <EditVoucher 
            CloseEvent={EditHandleClose}
            MarchantData={Marchant}
            onSuccess={refreshMerchant}
            currentVoucher={currentVoucher}
        
            />
       </Box>
     </Modal>
   </div>
<Typography
            gutterBottom
            variant="h5"
            component="div"
            sx={{ padding: "9px",fontWeight: 'bold'}}
          >
            Voucher List
          </Typography>
          <Divider />
          <Box height={10} />
          <Box height={10} />
          <Stack direction="row" spacing={2} className="my-2 mb-2" sx={{ padding: '10px' }}>
        
            <Typography
              variant="h6"
              component="div"
              sx={{ flexGrow: 1 }}
            ></Typography>
            <Button variant="contained" endIcon={<AddCircleIcon />}  sx={{
        backgroundColor: "gray", // Gray background color
        color: "white", // Text color (optional for contrast)
        "&:hover": {
          backgroundColor: "#6c757d", // Slightly darker gray for hover effect
        },
      }}  onClick={handleOpen} >
              Add New Voucher
            </Button>
          </Stack>
          <Box height={10} />
      <TableContainer sx={{ maxHeight: 440 }}>
        <Table stickyHeader aria-label="sticky table">
          <TableHead>
          <TableRow>
                  <TableCell align="left" style={{ minWidth: "100px",fontWeight: 'bold' }}>
                    Id
                  </TableCell>
                  <TableCell align="left" style={{ minWidth: "100px",fontWeight: 'bold' }}>
                    Voucher
                  </TableCell>
                  <TableCell align="left" style={{ minWidth: "100px",fontWeight: 'bold' }}>
                    Price
                  </TableCell>
                  <TableCell align="left" style={{ minWidth: "100px",fontWeight: 'bold' }}>
                    Team Discount(%)
                  </TableCell>
                  <TableCell align="left" style={{ minWidth: "100px",fontWeight: 'bold' }}>
                   Individual Discount(%)
                  </TableCell>
                  <TableCell align="left" style={{ minWidth: "100px",fontWeight: 'bold' }}>
                  Min.Team Duration
                  </TableCell>
                  <TableCell align="left" style={{ minWidth: "100px",fontWeight: 'bold' }}>
                  Min.Team Mumber
                  </TableCell>
                  <TableCell align="left" style={{ minWidth: "100px",fontWeight: 'bold' }}>
                  Date
                  </TableCell>
                  <TableCell align="left" style={{ minWidth: "100px",fontWeight: 'bold' }}>
                    Action
                  </TableCell>
                </TableRow>
          </TableHead>
          <TableBody>
                      {/* Loading Handling */}
                      {ShowLoading ? (
    
    <TableRow>
<TableCell colSpan={12} align="center">
<Box
          sx={{
            bgcolor: '#e0e0e0',
            p: 8,
            width: '100%',
            display: 'flex',
            justifyContent: 'center',
          }}
        >
          <Skeleton
            sx={{ bgcolor: '#e0e0e0' }}
            variant="rectangular"
            width={500}
            height={50}
          />
        </Box>
</TableCell>
</TableRow>




) : (
          
          rows
                  .slice(page * rowsPerPage, page * rowsPerPage + rowsPerPage)
                  .map((row) => {
                    return (
                      <TableRow
                        hover
                        role="checkbox"
                        tabIndex={-1}
                        key={row.ID}
                      >
                        
                        
                        <TableCell align="left">#{row.ID}</TableCell>
                        <TableCell align="left">{row.voucher_name}</TableCell>
                        <TableCell align="left">{row.price}</TableCell>
                        <TableCell align="left">{row.team_discount_percentage}</TableCell>
                        <TableCell align="left">{row.discount_percentage}</TableCell>
                        <TableCell align="left">{row.minimum_team_duration}</TableCell>
                        <TableCell align="left">{row.required_team_member}</TableCell>
                        <TableCell align="left">
    {new Date(row.CreatedAt).toLocaleDateString("en-US", {
      day: "numeric",
      month: "long",
      year: "numeric",
    })}
  </TableCell>
                        <TableCell align="left">
                          <Stack spacing={2} direction="row">
                                <Tooltip title="Edit">
                          <EditIcon
                              style={{
                                fontSize: "20px",
                                color: "blue",
                                cursor: "pointer",
                              }}
                              className="cursor-pointer"
                              onClick={() => EditHandleOpen(row)}
                            />
                            </Tooltip>
                            <Tooltip title="Delete">
                            <DeleteIcon
                              style={{
                                fontSize: "20px",
                                color: "darkred",
                                cursor: "pointer",
                              }}
                              onClick={() =>  handleDelete(row.ID)}
                            />
                            
                            </Tooltip>
                          </Stack>
                        </TableCell>
                      </TableRow>
                    );
                  })
                )}
          </TableBody>
        </Table>
      </TableContainer>
      <TablePagination
        rowsPerPageOptions={[10, 25, 100]}
        component="div"
        count={rows.length}
        rowsPerPage={rowsPerPage}
        page={page}
        onPageChange={handleChangePage}
        onRowsPerPageChange={handleChangeRowsPerPage}
      />
    </Paper>
  );
}