import React, { useState,useEffect } from 'react'
import Paper from '@mui/material/Paper';
import Table from '@mui/material/Table';
import TableBody from '@mui/material/TableBody';
import TableCell from '@mui/material/TableCell';
import TableContainer from '@mui/material/TableContainer';
import TableHead from '@mui/material/TableHead';
import TablePagination from '@mui/material/TablePagination';
import TableRow from '@mui/material/TableRow';
import Typography from "@mui/material/Typography";
import Divider from "@mui/material/Divider";
import Stack from '@mui/material/Stack';
import Autocomplete from "@mui/material/Autocomplete";
import AddCircleIcon from "@mui/icons-material/AddCircle";
import Button from "@mui/material/Button";
import Box from "@mui/material/Box";
import TextField from "@mui/material/TextField";
import  '../../../assets/css/dashboard.css';
import { useNavigate } from "react-router-dom";
import EditIcon from "@mui/icons-material/Edit";
import DeleteIcon from "@mui/icons-material/Delete";
import Tooltip from '@mui/material/Tooltip';
import Modal from '@mui/material/Modal';
import CategoryForm from './CategoryForm';
import { fetchCategories } from "../../../api/Api";
import EditCategory from './EditCategory';
import Alert from '@mui/material/Alert';
import AlertTitle from '@mui/material/AlertTitle';
import Swal from 'sweetalert2';
import { deleteCategory } from '../../../api/Api';
import { CircularProgress } from "@mui/material";


const style = {
    position: 'absolute',
    top: '50%',
    left: '50%',
    transform: 'translate(-50%, -50%)',
    width: 400,
    bgcolor: 'background.paper',
    border: '2px solid #000',
    boxShadow: 24,
    p: 4,
  };







export default function Categorylist() {
  const [page, setPage] = React.useState(0);
  const [rowsPerPage, setRowsPerPage] = React.useState(7);

    const navigate = useNavigate();

    //for modal
    const [open, setOpen] = React.useState(false);
    const handleOpen = () => setOpen(true);
    const handleClose = () => setOpen(false);
  


  
    const [rows, setrows] = useState([]);
    const [loading, setLoading] = useState(true);
    const [error, setError] = useState(null);
    const [searchInput, setSearchInput] = useState(""); // Track input 

    const [currentCategory, setCurrentCategory] = useState(null);

    

         //for edit
         const [EditOpen, setEditOpen] = React.useState(false);
         const EditHandleOpen = (category) => {
          setCurrentCategory(category);
           
           setEditOpen(true);
     
         }
         const EditHandleClose = () => {
          setCurrentCategory(null);
          setEditOpen(false);
          }
    //for search function
    const [allRows, setAllRows] = useState([]); // To store all categories
// Fetch categories data from api when the component mounts

      const getCategories = async () => {
        try {
          const data = await fetchCategories(); // Call the API function
          
          setrows(data.data); 
          setAllRows(data.data);
    
      
        } catch (err) {
          setError(err.message);
        } finally {
          setLoading(false);
        }
      };
  


    useEffect(() => {
      getCategories();
    }, []);

    //delete category
    const handleDelete = async (categoryId) => {
      const result = await Swal.fire({
        title: 'Are you sure?',
        text: 'You won\'t be able to revert this!',
        icon: 'warning',
        showCancelButton: true,
        confirmButtonColor: '#3085d6',
        cancelButtonColor: '#d33',
        confirmButtonText: 'Yes, delete it!'
      });
  
      if (result.isConfirmed) {
        try {
          await deleteCategory(categoryId);

          Swal.fire('Deleted!', 'The category has been deleted.', 'success');
          getCategories();
        } catch (err) {
          Swal.fire('Error!', 'Failed to delete the category.', 'error');
        }
      }
    };

    // Callback for successful category creation
   const handleCategoryCreated = () => {
    getCategories(); // Refresh the category list
    handleClose();   // Close the modal
  };

  //callback for successfully edit category
  const handleCategoryEdit = () => {
    getCategories(); // Refresh the category list
    EditHandleClose();   // Close the modal
  };

    if (error) {
      return (
        <Alert severity="error" sx={{ mt: 2 }}>
         <AlertTitle>Error</AlertTitle>
         There seems to be an issue with fetching category data. Your token may be invalid or there might be a network issue. Please check your connection or log in again.
         </Alert>
      )
    }

  const handleChangePage = (event, newPage) => {
    setPage(newPage);
  };

  const handleChangeRowsPerPage = (event) => {
    setRowsPerPage(+event.target.value);
    setPage(0);
  };

  // Create a unique listc category
  const uniqueRows = rows.filter(
    (row, index, self) =>
      index === self.findIndex((r) => r.name === row.name)
  );

//handel search
  const handleSearchInputChange = (event, value) => {
    setSearchInput(value); // Update input value

    if (value) {
      const filteredData = allRows.filter((row) =>
        row.name.toLowerCase().includes(value.toLowerCase())
      );
      setrows(filteredData); // Set filtered rows
    } else {
      setrows(allRows); // Reset to all data if input is empty
    }
  };



  return (

    
    <Paper sx={{ width: '100%', overflow: 'hidden' }}>
  {/* add modal */}
<div>
     
  
      <Modal
        open={open}
        
        aria-labelledby="modal-modal-title"
        aria-describedby="modal-modal-description"
      >
        <Box sx={style}>
             <CategoryForm
               CloseEvent={handleClose}
               onSuccess={handleCategoryCreated}
             />
        </Box>
      </Modal>
    </div>
    {/* edit model */}
    <div>
     
  
      <Modal
        open={EditOpen}
        
        aria-labelledby="modal-modal-title"
        aria-describedby="modal-modal-description"
      >
        <Box sx={style}>
        <EditCategory
               CloseEvent={EditHandleClose}
               onSuccess={handleCategoryEdit}
               category={currentCategory}
              
             />
            
        </Box>
      </Modal>
    </div>

<Typography
            gutterBottom
            variant="h5"
            component="div"
            sx={{ padding: "10px",fontWeight: 'bold' }}
          >
            Categories List
          </Typography>
          <Divider />
          <Box height={10} />
          <Box height={10} />
          <Stack direction="row" spacing={2} className="my-2 mb-2"  sx={{ padding: '10px' }}>
            <Autocomplete
            
            disablePortal
                        id="combo-box-demo"
                        options={uniqueRows}
                        sx={{ width: 300 }}
                        Value={searchInput}
                        onInputChange={handleSearchInputChange}
                        getOptionLabel={(option) => option.name || ""}
                        renderInput={(params) => (
                          <TextField {...params} size="small" label="Search Category" />
                        )}
            />
            <Typography
              variant="h6"
              component="div"
              sx={{ flexGrow: 1}}
            ></Typography>
            <Button variant="contained" endIcon={<AddCircleIcon />}  sx={{
        backgroundColor: "gray", // Gray background color
        color: "white", // Text color (optional for contrast)
        "&:hover": {
          backgroundColor: "#6c757d", // Slightly darker gray for hover effect
        }
      }}  onClick={handleOpen} >
              Add New Category
            </Button>
          </Stack>
          <Box height={10} />
      <TableContainer sx={{ maxHeight: 600 }}>
        <Table stickyHeader aria-label="sticky table">
          <TableHead>
          <TableRow>
                 
                  <TableCell align="left" style={{ minWidth: "100px",fontWeight: 'bold' }}>
                    Category Name
                  </TableCell>
                  <TableCell align="left" style={{ minWidth: "100px",fontWeight: 'bold' }}>
                    Action
                  </TableCell>
                </TableRow>
          </TableHead>
          <TableBody>
            {/* Loading Handling */}
            {loading ? (
    
    <TableRow>
<TableCell colSpan={7} align="center">
<Box display="flex" flexDirection="column" alignItems="center" justifyContent="center" py={5}>
          <CircularProgress size={50} thickness={4.5} sx={{ color: "blue" }} />
          <Typography variant="body1" mt={2} fontWeight="bold" color="text.secondary">
            Loading data, please wait...
          </Typography>
        </Box>
</TableCell>
</TableRow>




) : (

          
          rows
                  .slice(page * rowsPerPage, page * rowsPerPage + rowsPerPage)
                  .map((row) => {
                    return (
                      <TableRow
                        hover
                        role="checkbox"
                        tabIndex={-1}
                        key={row.code}
                      >
                   
                        <TableCell align="left">{row.name}</TableCell>
                    
                        <TableCell align="left">
                          <Stack spacing={1} direction="row">
                          <Tooltip title="Edit">
                          <EditIcon
                              style={{
                                fontSize: "20px",
                                color: "blue",
                                cursor: "pointer",
                                
                              }}
                              className="cursor-pointer"
                              onClick={() => EditHandleOpen(row)}
                            
                            />
                              </Tooltip>
                            <Tooltip title="Delete">
                            <DeleteIcon
                              style={{
                                fontSize: "20px",
                                color: "darkred",
                                cursor: "pointer",
                              }}
                              
                              onClick={() =>  handleDelete(row.ID)}
                            
                            />
                         </Tooltip>
                          </Stack>
                        </TableCell>
                      </TableRow>
                    );
                  })
                )}
          </TableBody>
        </Table>
      </TableContainer>
      <TablePagination
        rowsPerPageOptions={[10, 25, 100]}
        component="div"
        count={rows.length}
        rowsPerPage={rowsPerPage}
        page={page}
        onPageChange={handleChangePage}
        onRowsPerPageChange={handleChangeRowsPerPage}
      />
    </Paper>
  );
}