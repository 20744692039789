import React, { useState } from "react";
import { Box, TextField, Button, Typography, Card } from "@mui/material";

const ChangePassword = () => {
  const [formData, setFormData] = useState({
    currentPassword: "",
    newPassword: "",
    confirmPassword: "",
  });

  const [error, setError] = useState("");

  const handleChange = (e) => {
    const { name, value } = e.target;
    setFormData({ ...formData, [name]: value });
  };

  const handleSubmit = (e) => {
    e.preventDefault();

    // Validation Logic
    if (formData.newPassword !== formData.confirmPassword) {
      setError("New password and confirmation do not match.");
      return;
    }

    if (formData.newPassword.length < 8) {
      setError("Password must be at least 8 characters long.");
      return;
    }

    setError("");
    console.log("Password Changed Successfully!", formData);
    alert("Password Changed Successfully!");
    setFormData({ currentPassword: "", newPassword: "", confirmPassword: "" });
  };

  return (
    <Box
      sx={{
        maxWidth: "500px",
        mx: "auto",
        mt: 4,
        px: 2,
      }}
    >
      <Card
        sx={{
          p: 4,
          borderRadius: 4,
          boxShadow: 3,
        }}
      >
        

        <Typography variant="h5" gutterBottom   sx={{ textAlign: "center", mb: 3 }}>
              Change Password
            </Typography>

        <form onSubmit={handleSubmit}>
          {/* Current Password */}
          <TextField
            label="Current Password"
            type="password"
            name="currentPassword"
            value={formData.currentPassword}
            onChange={handleChange}
            fullWidth
            required
            variant="outlined"
            margin="normal"
          />

          {/* New Password */}
          <TextField
            label="New Password"
            type="password"
            name="newPassword"
            value={formData.newPassword}
            onChange={handleChange}
            fullWidth
            required
            variant="outlined"
            margin="normal"
          />

          {/* Confirm Password */}
          <TextField
            label="Confirm New Password"
            type="password"
            name="confirmPassword"
            value={formData.confirmPassword}
            onChange={handleChange}
            fullWidth
            required
            variant="outlined"
            margin="normal"
          />

          {/* Error Message */}
          {error && (
            <Typography
              variant="body2"
              color="error"
              sx={{ mt: 1, mb: 2, textAlign: "center" }}
            >
              {error}
            </Typography>
          )}

          {/* Submit Button */}
          <Button
            type="submit"
            variant="contained"
            color="primary"
            size="large"
            fullWidth
            sx={{
              mt: 2,
              fontWeight: "bold",
              py: 1.5,
              textTransform: "none",
            }}
          >
            Update Password
          </Button>
        </form>
      </Card>
    </Box>
  );
};

export default ChangePassword;
