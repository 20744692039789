import React, { useState,useEffect } from "react";
import Paper from '@mui/material/Paper';
import Table from '@mui/material/Table';
import TableBody from '@mui/material/TableBody';
import TableCell from '@mui/material/TableCell';
import TableContainer from '@mui/material/TableContainer';
import TableHead from '@mui/material/TableHead';
import TableRow from '@mui/material/TableRow';
import Typography from "@mui/material/Typography";
import Divider from "@mui/material/Divider";
import Stack from '@mui/material/Stack';
import Autocomplete from "@mui/material/Autocomplete";
import Button from "@mui/material/Button";
import Box from "@mui/material/Box";
import TextField from "@mui/material/TextField";
import  '../../assets/css/dashboard.css';
import { fetchOngoingTeams } from "../../api/Api";
import { CircularProgress } from "@mui/material";
import Alert from '@mui/material/Alert';
import AlertTitle from '@mui/material/AlertTitle';



export default function OnGoingTeamslist() {
  const [teams, setTeams] = useState([]);  
  const [currentPage, setCurrentPage] = useState(1);  
  const [loading, setLoading] = useState(false);
  const [error, setError] = useState(null);
  const [pagination, setPagination] = useState(null); 
  const [filteredTeams, setFilteredTeams] = useState([]); // For search results

  const limit = 7; // Number of items per page

  useEffect(() => {
      const getTeams = async () => {
          setLoading(true);
          try {
            const token = localStorage.getItem("token");
              const data = await fetchOngoingTeams(currentPage, limit,token);
              console.log(data);
               setFilteredTeams(data.data);
              setTeams(data.data);
              setPagination(data.pagination);
              setError(null); 
          } catch (error) {
            setError(error.message);
          } finally {
              setLoading(false);
          }
      };

      getTeams();
  }, [currentPage]); 


// **Search Function**
const handleSearch = (event, value) => {
  if (!value) {
      setFilteredTeams(teams); // Reset to full list when search is cleared
  } else {
      // Filter by ID or Voucher Name
      const filtered = teams.filter(team => 
          team.ID.toString().includes(value) || 
          team.Voucher.voucher_name.toLowerCase().includes(value.toLowerCase())
      );
      setFilteredTeams(filtered);
  }
};
    if (error) {
      return (
        <Alert severity="error" sx={{ mt: 2 }}>
         <AlertTitle>Error</AlertTitle>
           There was an issue fetching the OnGing Teams data. Please check your network connection or try again later.
         </Alert>
      )
    }


  return (
    <Paper sx={{ width: '100%', overflow: 'hidden' }}>
  <Stack direction="row" spacing={2} className="my-2 mb-2">
<Typography
            gutterBottom
            variant="h5"
            component="div"
            sx={{ padding: "20px",fontWeight: 'bold' }}
          >
            OnGoing Teams
          </Typography>
          <Typography
              variant="h6"
              component="div"
              sx={{ flexGrow: 1 }}
            ></Typography>
<Autocomplete
          disablePortal
          id="team-search"
          options={teams.map(team => team.ID.toString())} // Search by Team ID
          sx={{ width: 300, padding: '15px' }}
          onInputChange={handleSearch} // Call search function on change
          renderInput={(params) => (
            <TextField {...params} size="small" label="Search Team ID / Voucher" />
          )}
        />
                </Stack>
          <Divider />
          <Box height={10} />
          <Box height={10} />
          <Stack direction="row" spacing={2} className="my-2 mb-2">
            
          </Stack>
          <Box height={10} />
      <TableContainer sx={{ maxHeight: 600 }}>
        <Table stickyHeader aria-label="sticky table">
          <TableHead>
          <TableRow>
                  <TableCell align="left" style={{ minWidth: "100px",fontWeight: 'bold' }}>
                    Team ID
                  </TableCell>
                  <TableCell align="left" style={{ minWidth: "100px",fontWeight: 'bold' }}>
                    Team Creator
                  </TableCell>
                  <TableCell align="left" style={{ minWidth: "100px",fontWeight: 'bold' }}>
                    Team Member
                  </TableCell>
                  <TableCell align="left" style={{ minWidth: "100px",fontWeight: 'bold' }}>
                    Voucher
                  </TableCell>
                  <TableCell align="left" style={{ minWidth: "100px",fontWeight: 'bold' }}>
                    Voucher Amount
                  </TableCell>
                  <TableCell align="left" style={{ minWidth: "100px",fontWeight: 'bold' }}>
                    Country
                  </TableCell>
                  <TableCell align="left" style={{ minWidth: "100px",fontWeight: 'bold' }}>
                    Expired Date
                  </TableCell>
                </TableRow>
          </TableHead>
          <TableBody>

  {/* Loading Handling */}
          {loading ? (
    
            <TableRow>
      <TableCell colSpan={7} align="center">
        <Box display="flex" flexDirection="column" alignItems="center" justifyContent="center" py={5}>
          <CircularProgress size={50} thickness={4.5} sx={{ color: "blue" }} />
          <Typography variant="body1" mt={2} fontWeight="bold" color="text.secondary">
            Loading data, please wait...
          </Typography>
        </Box>
      </TableCell>
    </TableRow>




) : (
  filteredTeams.map((row) => (
    <TableRow hover role="checkbox" tabIndex={-1} key={row.ID}>
      <TableCell align="left">#{row.ID}</TableCell>
      <TableCell align="left">{row.Voucher.country_info.currency_symbol}</TableCell>
      <TableCell align="left">5</TableCell>
      <TableCell align="left">{row.Voucher.voucher_name}</TableCell>
      <TableCell align="left">
        {row.Voucher.country_info.currency_symbol}
        {row.Voucher.price}
      </TableCell>
      <TableCell align="left">{row.Voucher.country_info.country_name}</TableCell>
      <TableCell align="left">12 Jan 2025</TableCell>
    </TableRow>
  ))
)}
          </TableBody>
        </Table>

      </TableContainer>


{/* Pagination Controls */}
{pagination && (
                <Box display="flex" justifyContent="flex-end" alignItems="center" mt={3} mb={3} pr={2}>
                    <Button
                        onClick={() => setCurrentPage(prev => prev - 1)}
                        disabled={currentPage === 1}
                        variant="contained"
                        sx={{
                            backgroundColor: currentPage === 1 ? "gray" : "blue",
                            color: "white",
                            mr: 1,
                            "&:hover": {
                                backgroundColor: currentPage === 1 ? "gray" : "darkblue",
                            },
                        }}
                    >
                        ◀ Previous
                    </Button>

                    <Typography
                        variant="body1"
                        component="span"
                        sx={{
                            fontWeight: "bold",
                            mx: 2,
                            px: 2,
                            py: 1,
                            backgroundColor: "#f0f0f0",
                            borderRadius: "8px",
                            boxShadow: "0px 2px 5px rgba(0, 0, 0, 0.2)",
                        }}
                    >
                        Page {pagination.current_page} of {pagination.total_pages}
                    </Typography>

                    <Button
                        onClick={() => setCurrentPage(prev => prev + 1)}
                        disabled={currentPage === pagination.total_pages}
                        variant="contained"
                        sx={{
                            backgroundColor: currentPage === pagination.total_pages ? "gray" : "blue",
                            color: "white",
                            ml: 1,
                            "&:hover": {
                                backgroundColor: currentPage === pagination.total_pages ? "gray" : "darkblue",
                            },
                        }}
                    >
                        Next ▶
                    </Button>
                </Box>
            )}
        

      {/* <TablePagination
        rowsPerPageOptions={[10, 25, 100]}
        component="div"
        count={rows.length}
        rowsPerPage={rowsPerPage}
        page={page}
        onPageChange={handleChangePage}
        onRowsPerPageChange={handleChangeRowsPerPage}
      /> */}
    </Paper>
  );
}