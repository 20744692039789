import React, { useState } from "react";
import Swal from "sweetalert2"; 
import  '../../../assets/css/alert.css';
import CloseIcon from '@mui/icons-material/Close';
import { IconButton } from '@mui/material';
import { updateCategory } from "../../../api/Api";
import Typography from "@mui/material/Typography";
import CircularProgress from '@mui/material/CircularProgress';

const EditCategory = ({CloseEvent,onSuccess,category}) => {

 const [newCategoryName, setNewCategoryName] = useState(category?.name || "");

  const [error, setError] = useState("");
  const [loading, setLoading] = useState(false);


//onclick
const handleUpdateCategory = async (event) => {
  event.preventDefault();



  setError("");
  setLoading(true);

  try {
    await updateCategory(category.ID,newCategoryName);
      Swal.fire({
          icon: "success",
          title: "Category updated successfully!",
          toast: true,
          position: "top-right",
          
          showConfirmButton: false,
          timer: 1000,
          timerProgressBar: true,
          background: "linear-gradient(to right, #008000, #32CD32)", 
          color: "#fff",
          iconColor: "#fff",
          customClass: {
            popup: "premium-toast-popup",
            container: "swal-toast-container",
          },
        })
    if (onSuccess) onSuccess();
    CloseEvent(); // Close modal
  } catch (err) {

    Swal.fire({
        icon: "error",
        title: "Failed to update category. Please try again.",
        toast: true,
        position: "top-right",
        
        showConfirmButton: false,
        timer: 1500,
        timerProgressBar: true,
        background: "linear-gradient(to right, red, red)", 
        color: "#fff",
        iconColor: "#fff",
        customClass: {
          popup: "premium-toast-popup",
          container: "swal-toast-container",
        },
      })

  } finally {
    setLoading(false);
  }


};



  return (
    <div
      style={{
        maxWidth: "600px",
       
        padding: "20px",
        borderRadius: "8px",
       
        
      }}
    >
          <h2 style={{ textAlign: "center", color: "#333", marginBottom: "20px" }}>
        Edit Category
      </h2>

      <IconButton style={{position:"absolute",top:'0',right:'0'}} 
      onClick={CloseEvent}>
      <CloseIcon />

      </IconButton>
   

      <form onSubmit={handleUpdateCategory }>
        <div style={{ marginBottom: "15px" }}>
          <label
            htmlFor="name"
            style={{
              display: "block",
              marginBottom: "5px",
              color: "#555",
              fontWeight: "bold",
            }}
          >
            Category Name
          </label>
          {error && (
        <Typography variant="body2" color="error" sx={{ marginBottom: "8px" }}>
          {error}
        </Typography>
      )}
          <input
            type="text"
            id="name"
            name="name"
            value={newCategoryName}
            onChange={(e) => setNewCategoryName(e.target.value)}
            disabled={loading}
            required
            style={{
              width: "100%",
              padding: "10px",
              borderRadius: "4px",
              border: "1px solid #ccc",
              fontSize: "16px",
            background:'#f9f9f9'
            }}
            placeholder="Enter category name"
          />
        </div>
  
        <button
          type="submit"
          style={{
            width: "100%",
            padding: "10px",
            backgroundColor: "gray",
            color: "#fff",
            border: "none",
            borderRadius: "4px",
            fontSize: "16px",
            fontWeight: "bold",
            cursor: "pointer",
          }}
        
        >
{loading ? <CircularProgress
          size={16}
          sx={{
          
            color: 'inherit',
          }}
        /> : "Submit"}
        </button>
      </form>
    </div>
  );
};

export default EditCategory;
